import React, { PureComponent } from 'react'
// import PropTypes from 'prop-types'
import { Header } from 'semantic-ui-react'

import Blueprint from '../Partials/Blueprint'
// import { renderField, renderSelector, renderRadio, renderCheckbox, renderTextArea } from '../Partials/forms'
// import { required } from '../Partials/forms/validate'
export default class ServicesConfirmation extends PureComponent {
    // static propTypes = {

    // }
    componentDidMount() {
        this.props.handleHeaderTextChange("Got it!",null);
    }

    render() {
        // const colors = ['Red', 'Orange', 'Yellow', 'Green', 'Blue', 'Indigo', 'Violet'];

        return (
            <Blueprint
                title="Services"
                progressValue="25"
                description="Request NEW Services"
                tallHeight={true}
                section="services"
                nextText="Finish"
                // handleFormSubmit={this.props.handleFormSubmit}
                valid={true}
                endOfSection={true}
                form="servicesNew"
            >
                <div>
                    <div>
                        <h3>I’d like to explore adding new Services to my restaurant.</h3>
                    </div>

                    <div className="divide-top-no-divider">
                    {
                                localStorage.getItem("location_address2")
                                ?
                                <p>
                                    {/* {this.props.restFormVals.restaurant_name !== undefined ? this.props.restFormVals.restaurant_name : localStorage.get("restaurant_name")}<br /> */}
                                    {localStorage.getItem("restaurant_name")}<br />
                                    {localStorage.getItem("location_address1")} <br />
                                    {localStorage.getItem("location_address2")} <br />
                                    {localStorage.getItem("location_city")}, {localStorage.getItem("location_state")} <br />
                                    {localStorage.getItem("location_zip")}<br />
                                </p>
                                :
                                <p>
                                    {/* {this.props.restFormVals.restaurant_name !== undefined ? this.props.restFormVals.restaurant_name : localStorage.get("restaurant_name")}<br /> */}
                                    {localStorage.getItem("restaurant_name")}<br />
                                    {localStorage.getItem("location_address1")} <br />
                                    {localStorage.getItem("location_city")}, {localStorage.getItem("location_state")} <br />
                                    {localStorage.getItem("location_zip")}<br />
                                </p>                                
                            }
                    </div>

                    <div className="divide-top-no-divider">
                        <div>
                            <h5>
                                Our team knows online ordering inside and out. Keep an eye on your email {localStorage.getItem("contact_email")} for a custom report detailing the services currently available at this location. 
                                <br />
                                <br />
                                If you need immediate assistance, reach out to our New Services team at 800-123-1234.
                            </h5>
                        </div>
                        <div className="ui left aligned segment service-segment">
                            <Header as="h5">
                                {
                                    <i className="big exclamation icon"></i>
                                }
                                <Header.Content>
                                    <Header.Subheader>
                                        If you currently have any online ordering services make sure to connect them. <br/>
                                        Your account needs at least 1 live service connected for your equipment to ship.
                                    </Header.Subheader>
                                </Header.Content>
                            </Header>
                        </div>
                    </div>
                </div>
            </Blueprint>
        )
    }
}
