import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk'
import ReactGA from 'react-ga'
import {StripeProvider} from 'react-stripe-elements'


import * as serviceWorker from './serviceWorker';

import './index.scss';
import App from './App';
import reducers from './reducers'
import { GOOGLE_ANALYTICS_TRACKING_CODE, STRIPE_API_KEY } from './config'


ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_CODE);

const store = process.env.NODE_ENV === 'production' ? createStore(reducers, applyMiddleware(thunk)) : createStore(reducers, composeWithDevTools(applyMiddleware(thunk))) 

ReactDOM.render(
    <Provider store={store}>
        <StripeProvider apiKey={STRIPE_API_KEY}>
            <App />
        </StripeProvider>
    </Provider>, 
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
