import { FORM_CHECK, SECTION_STATUS, } from '../actions/types'

const INITIAL_STATE = {
    account: false || JSON.parse(localStorage.getItem('account')),
    contract: false || JSON.parse(localStorage.getItem('contract')),
    billing: false || JSON.parse(localStorage.getItem('billing')),
    services: false || JSON.parse(localStorage.getItem('services')),
    calendly: false || JSON.parse(localStorage.getItem('calendly')),
    help: true
}

export default (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case FORM_CHECK:
            return {...state, [action.payload]: true}
        case SECTION_STATUS:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state
    }
};
