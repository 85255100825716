import React, { PureComponent } from 'react'
import { Field, reduxForm } from 'redux-form'
// import { Button, Checkbox, Form } from 'semantic-ui-react'
// import PropTypes from 'prop-types'

import {cuisine_types} from '../../dummyData/constants'
import { renderField, renderSelector, renderCheckbox } from '../Partials/forms'
import normalizePhone from '../Partials/forms/normalizePhone'
import { required } from '../Partials/forms/validate'
import Blueprint from '../Partials/Blueprint'
import RestaurantLocationv2 from './RestaurantLocationv2'

class RestaurantInfo extends PureComponent {
    // static propTypes = {

    // }
    componentDidMount() {
        this.props.handleHeaderTextChange("Step 1 - Setup Your Account",null,true);
        this.props.handleSectionProgress("25");
    }

    render() {
        // const restoTypes = ['Brick & Mortar', 'Virtual Kitchen', 'Food Hall', 'Food Truck', 'Commissary Kitchen'];

        return (
            <Blueprint 
                // title="Account Creation"
                // description="Get started with some basic contact information about you and your restaurant."
                progressValue="8.3"
                section="account"
                // nextPage="location
                prevPage=""
                form="restoInfo"
                valid={this.props.valid}
                endOfSection={true}
            >
                <div className="hundo-height">
                    <h3>Tell us a bit about your Restaurant</h3>
                    <h4>* = required</h4>

                    <form className="ui form">                         
                        <Field
                            name="restaurant_name"
                            component={renderField}
                            // component={Form.Input}
                            validate={required}
                            placeholder="Restaurant Name"
                            label="Restaurant Name *"
                            type="text"
                        />
                        {/* <Field
                            name="restaurant_website"
                            component={renderField}
                            // component={Form.Input}
                            placeholder="Website"
                            label="Website"
                            type="text"
                        /> */}

                        {/* <Field
                            name="accountWebsiteCheckbox"
                            component={renderCheckbox}
                            label="We are looking into, or are in the process of building a website."
                        /> */}

                        <Field
                            name="restaurant_entity_name"
                            component={renderField}
                            validate={required}
                            placeholder="Legal Entity Name"
                            label="Legal Entity Name *"
                            type="text"
                            classes="twelve wide"
                        />
                        <Field
                            name="location_phone"
                            component={renderField}
                            validate={required}
                            normalize={normalizePhone}
                            placeholder="Restaurant Phone Number"
                            label="Restaurant Phone Number *"
                            type="text"
                            classes="twelve wide"
                        />
                        <Field
                            name="restaurant_type"
                            component={renderSelector}
                            validate={required}
                            arr={cuisine_types}
                            label="Cuisine Type *"
                            selection="--"
                            classes="eight wide mobile six wide tablet six wide computer price-plan"
                        />
                    </form>
                    <RestaurantLocationv2 />
                </div>
            </Blueprint>
        )
    }
}

export default reduxForm({
    form: 'restoInfo', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    // validate
})(RestaurantInfo)