import React, { PureComponent } from 'react'
// import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
// import { Checkbox } from 'semantic-ui-react'
// import PropTypes from 'prop-types'

import history from '../../history'
import Blueprint from '../Partials/Blueprint'
import { renderTextArea } from '../Partials/forms'

class Help extends PureComponent {
//   static propTypes = {

//   }

goBack = (e) => {
    e.preventDefault()
    history.goBack()
}

    render() {
        let mailLink = `mailto:${this.props.salesRepresentativeEmail}`

        return (
            <Blueprint
                nextText="Submit"
                section="help"
                endOfSection={true}
                valid={this.props.valid}
                prevPage="home"
                form="help"
                hideButtons={true}
            >
                <div className="vert-center-parent">
                    <div className="ui center aligned container help-text">
                        <h4>
                            If you need immediate assistance, contact {this.props.salesRepresentative} at <span className="underline"><a href={mailLink}>{this.props.salesRepresentativeEmail}</a></span> or call {this.props.salesRepresentativePhone}
                        </h4>
                        {/* <h2>Stuck? Have a question? We’re here to help.</h2>
                        <h5>If you need immediate assistance, contact {this.props.salesRepresentative} at 800-236-8943.</h5> */}

                        <div onClick={this.goBack}>
                            <button className="circular ui left floated button back-button">
                                &lt; Back
                            </button>
                            {/* <Link to="/services/new">
                            </Link> */}
                        </div>
                    </div>
                    {/* <div>
                        <form className="ui form">
                            <Field 
                                name="help"
                                component={renderTextArea}
                                placeholder="info"
                            />
                        </form>
                    </div> */}
                </div>
            </Blueprint>
        )
    }
}


export default reduxForm({
    form: 'help',
    // validate
})(Help)