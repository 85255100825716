import React, { PureComponent } from 'react'
import { Field, reduxForm, formValueSelector, getFormValues, change } from 'redux-form'
import { connect } from 'react-redux'
// import PropTypes from 'prop-types'

import { renderField, renderSelector, renderCheckbox, renderRadio, } from '../Partials/forms'
// import normalizePhone from '../Partials/forms/normalizePhone'
import Blueprint from '../Partials/Blueprint'
import { states } from '../../dummyData/constants'
import { required } from '../Partials/forms/validate'
import ShippingDetails from './ShippingDetails'
import normalizeZip from '../Partials/forms/normalizeZip';


class RestaurantLocation extends PureComponent {
    // static propTypes = {

    // }
    componentDidMount() {
        this.props.handleHeaderTextChange("Step 1 - Setup Your Account",null,true);
        this.props.handleSectionProgress("50");
    }
    componentDidUpdate(prevProps) {
        this.copyLocationInfoToShipping()
        // if (this.props.shipmentOption === true) {
        //     this.copyLocationInfoToShipping()
        // }
    }

    renderShippingDetails = () => {
        console.log('options', this.props.shipmentOption)
        if (this.props.shipmentOption === true) {
            return (
                <ShippingDetails shipmentOption={this.props.shipmentOption}/>
                )
            }
    }

    copyLocationInfoToShipping = () => {
        if (this.props.formVals && this.props.shipmentOption === true) {
            this.props.change('shipping_address1', this.props.formVals.location_address1)
            this.props.change('shipping_address2', this.props.formVals.location_address2)
            this.props.change('shipping_city', this.props.formVals.location_city)
            this.props.change('shipping_state', this.props.formVals.location_state)
            this.props.change('shipping_zip', this.props.formVals.location_zip)

            console.log("changee", this.props.formVals.shipping_address1)
        } else if (!this.props.formVals && this.props.shipmentOption === false) {
            this.props.change('shipping_address1', '')
            this.props.change('shipping_address2', '')
            this.props.change('shipping_city', '')
            this.props.change('shipping_state', '')
            this.props.change('shipping_zip', '')
        }
    }



    render() {
        return (
            <Blueprint 
                // title="Account Creation"
                // description="Get started with some basic contact information about you and your restaurant."
                progressValue="16.6"
                section="account"
                nextPage="install-contact"
                endOfSection={true}
                nextText="Continue"
                prevPage="rest-info"
                // form="restoLocation"
                form='restoInfo'
                valid={this.props.valid}
            >
                <div>
                    <h3>Where is your Restaurant located?</h3>
                    <h4>* = required</h4>

                    <div>
                        <form className="ui form">
                            {/* <Field
                                name="resto_store_number"
                                component={renderField}
                                // validate={required}
                                placeholder="Store Number(if applicable)"
                                type="text"
                                classes="ten wide"
                            /> */}
                            {/* <Field
                                name="location_phone"
                                component={renderField}
                                validate={required}
                                normalize={normalizePhone}
                                placeholder="Restaurant Phone Number"
                                label="Restaurant Phone Number *"
                                type="text"
                                classes="ten wide"
                            /> */}
                            <Field
                                name="location_address1"
                                component={renderField}
                                validate={required}
                                placeholder="Address"
                                label="Address *"
                                type="text"
                            />
                            <Field
                                name="location_address2"
                                component={renderField}
                                placeholder="Address"
                                type="text"
                            />
                            <div className="two fields">
                                <Field
                                    name="location_city"
                                    component={renderField}
                                    validate={required}
                                    placeholder="City"
                                    label="City *"
                                    type="text"
                                    classes="fourteen wide"
                                />
                                <Field
                                    name="location_state"
                                    component={renderSelector}
                                    validate={required}
                                    arr={states}
                                    label="State *"
                                    selection="State"
                                    classes="two-field-selector"
                                />
                            </div>
                            <Field
                                name="location_zip"
                                component={renderField}
                                validate={required}
                                normalize={normalizeZip}
                                placeholder="Zip Code"
                                label="Zip Code *"
                                type="text"
                                classes="six wide"
                            />
                            
                            {/* <p>Would you like your equipment shipped to the address above?</p> */}
                            <h3>Where should we ship your equipment?</h3>
                            <div className="ui grid" style={{marginTop: "5px"}}>
                                <Field
                                    name="location_shipment_option"
                                    component={renderCheckbox}
                                    label="Same info as restaurant location"
                                />

                                {/* <div className="eight wide column">
                                    <Field
                                        name="location_shipment_option"
                                        component={renderRadio}
                                        validate={required}
                                        label="Yes"
                                        radioValue="yes"
                                    />
                                </div>
                                <div className="eight wide column">
                                    <Field
                                        name="location_shipment_option"
                                        component={renderRadio}
                                        validate={required}
                                        label="I’ll supply shipping details below."
                                        radioValue="no"
                                    />
                                </div> */}
                            </div>
                        </form>
                    </div>
                    {/* {this.renderShippingDetails()} */}
                    <ShippingDetails shipmentOption={this.props.shipmentOption}/>
                </div>
            </Blueprint>
        )
    }
}


RestaurantLocation = reduxForm({
    // form: 'restoLocation', // <------ same form name
    form: 'restoInfo', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: false, // <------ unregister fields on unmount
    // validate
})(RestaurantLocation)

// const selector = formValueSelector('restoLocation')
const selector = formValueSelector('restoInfo')

RestaurantLocation = connect(state => {
    const shipmentOption = selector(state, 'location_shipment_option')
    let formVals = getFormValues('restoInfo')(state)
    return {
        shipmentOption,
        formVals,
        change
    }
})(RestaurantLocation)


export default RestaurantLocation