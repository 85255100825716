import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
// import PropTypes from 'prop-types'

import { handleFormSubmit, storeUserInfo, handleHeaderTextChange } from '../../actions'

import Help from './Help'

class index extends PureComponent {
//   static propTypes = {

//   }

componentDidMount() {
    // this.props.storeUserInfo()
    this.props.handleHeaderTextChange(`Stuck? Have a question? We’re here to help.`,null)
}

    render() {
        return (
            <Help 
                handleFormSubmit={this.props.handleFormSubmit} 
                salesRepresentative={this.props.salesRepresentative}
                salesRepresentativeEmail={this.props.salesRepresentativeEmail}
                salesRepresentativePhone={this.props.salesRepresentativePhone}
            />
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    // return { user: state.users.find(user => user.id === ownProps.userId) }
    return {}
}

export default connect(
    mapStateToProps, 
    {
        handleFormSubmit,
        storeUserInfo,
        handleHeaderTextChange,
    }
)(index)