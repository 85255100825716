import { ACCOUNT_INFO, } from '../actions/types'

const INITIAL_STATE = {
    
}

export default (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case ACCOUNT_INFO:
            return {...state, ...action.payload}

        default:
            return state
    }
};