import React, { PureComponent } from 'react'
import { Segment } from 'semantic-ui-react'
// import PropTypes from 'prop-types'

export default class HelpSegment extends PureComponent {
    // static propTypes = {

    // }

    render() {
        let mailLink = `mailto:${this.props.salesRepresentativeEmail}`
        
        return (
            <Segment inverted className="help-segment">
                    <h4>
                        If you have any questions or concerns please let me know at <span className="underline"><a href={mailLink}>{this.props.salesRepresentativeEmail}</a></span> or call {this.props.salesRepresentativePhone} <br />
                        - {this.props.salesRepresentative} 
                    </h4>
            </Segment>
        )
    }
}
