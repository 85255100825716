import React, { PureComponent } from 'react'
// import PropTypes from 'prop-types'

import jotScript from '../../contract/jotFormLogic'

export default class Iframe extends PureComponent {
//   static propTypes = {

//   }


    componentDidMount() {
        if (!this.props.source) {
            return <div>Loading...</div>;
        }

        //appends jotform associated logic to the iframe
        const s = document.createElement('script')
        s.type = 'text/javascript';
        s.async = true;
        s.innerHTML = `${jotScript}`
        this.instance.appendChild(s)

        window.addEventListener('message', this.handleIframeTask);
    }

    handleIframeTask = (e) => {
        // if (e.origin !== 'https://localhost:3000/') {
        //     return;
        // }

        if (e.data === 'submitted form') {
            console.log("in react")
            this.props.toggleNext()
        }
    };

    render() {
        return (
            <div>
                <iframe
                    ref={(el) => this.instance = el}
                    id={this.props.id}
                    title="RSA (Don't Touch)"
                    onLoad={window.parent.scrollTo(0,0)}
                    // allowTransparency="true"
                    // allowFullScreen="true"
                    src={this.props.source}
                    style={{width: "100%", height: "70vh", overflowX: "auto"}}
                    scrolling="yes"
                >
                </iframe>
            </div>

            // <iframe src={this.props.source} style={{width: "100%", height: "70vh"}}></iframe>
        )
    }
}




// const Iframe = ({ source }) => {


//     if (!source) {
//         return <div>Loading...</div>;
//     }

//     componentDidMount() {
//         window.addEventListener('message', this.handleIframeTask);
//     }

//     const src = source;     
//     return (
//             <iframe src={src}></iframe>
        
//         // <div className="">
//         //     <div className="">
//         //         <iframe src={src}></iframe>
//         //     </div>
//         // </div>
//     );
// };

// export default Iframe;