import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
// import { Grid,  } from 'semantic-ui-react'

import Blueprint from '../Partials/Blueprint'

export default class ServicesLanding extends PureComponent {
    componentDidMount() {
        // this.props.handleHeaderTextChange("You've completed your setup! Just one more question to get started right away!",null);
        this.props.handleHeaderTextChange("Connect Your Services",null);
    }

    render() {
        return (
            <Blueprint
                hideButtons={true}
            >
                <h3>You've completed your setup! Just one more question to get started right away!</h3>
                <div className="vert-center-parent">
                    <div>
                        {/* <div className="ui center aligned container">
                            <h3>
                                You've completed your setup! Just one more question to get started right away!
                            </h3>
                        </div> */}

                        <div className="ui center aligned container">
                            <h4>Do you have any existing online ordering accounts?</h4>
                            <p>Like DoorDash, Postmates, Uber Eats, or others.</p>
                            <div className="">
                                <Link to={`/services/existing`}>
                                    <button className="circular ui button next-button">
                                        Yes
                                    </button>
                                </Link>
                                <Link to="/services/new">
                                    <button className="circular ui button">
                                        Not Yet
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Blueprint>
        )
    }
}
