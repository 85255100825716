export const FETCH_USER = 'FETCH_USER';
export const FETCH_USERS = 'FETCH_USERS';
export const DELETE_USER = 'DELETE_USER'
export const STORE_USER_INFO = 'STORE_USER_INFO';

export const ACCOUNT_INFO = 'ACCOUNT_INFO';

export const FETCH_DATA = 'FETCH_DATA';
export const POST_DATA = 'POST_DATA';
export const FORM_DATA = 'FORM_DATA';
export const DELETE_DATA = 'DELETE_DATA';

export const AUTH_CHECK = 'AUTH_CHECK';
export const FORM_CHECK = 'FORM_CHECK';

export const STORE_ACH = 'STORE_ACH';
export const STORE_CC = 'STORE_CC';

export const NEXT_PAGE = 'NEXT_PAGE';
export const PREV_PAGE = 'PREV_PAGE';

export const HEADER_TEXT = 'HEADER_TEXT';
export const SECTION_PROGRESS = 'SECTION_PROGRESS'
export const SECTION_STATUS = 'SECTION_STATUS'
export const CHANGE_LOADED = 'CHANGE_LOADED'
