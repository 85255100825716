import React, { Component } from 'react'
import { connect } from 'react-redux'
// import PropTypes from 'prop-types'

import { handleFormSubmit,handleHeaderTextChange } from '../../actions'
import Blueprint from '../Partials/Blueprint'
import Contract from './Contract'
import Iframe from '../Partials/Iframe'


class index extends Component {
    // static propTypes = {
    //     prop: PropTypes
    // }

    state = {
        valid: false || JSON.parse(localStorage.getItem("contract")),
        //sandbox jotform
        //src=`https://form.jotform.com/91686361350156?app_id=${this.props.appId}`,
        //prod jotform
        src:`https://form.jotform.com/91416159977167?app_id=${this.props.appId}`,
    }

    componentDidMount() {
        this.props.handleHeaderTextChange(`Step 2 - Service Agreement`,null);
    }

    toggleNext = () => {
        this.setState({valid: true})
    }

    render() {
        return (
            <Blueprint
                valid = {this.state.valid}
                section="contract"
                endOfSection={true}
                form="contract"
                nextText="Continue"
                hideButtons={!this.state.valid}
            >
                <div>
                    <h3>
                        Please review your agreement and sign at the bottom.
                    </h3>
                    <Iframe
                        id="JotFormIFrame-91416159977167"
                        source={this.state.src} 
                        toggleNext={this.toggleNext} 
                        appId={this.props.appId}
                    />
                </div>
            </Blueprint>

            // <Contract handleFormSubmit={this.props.handleFormSubmit} handleHeaderTextChange={this.props.handleHeaderTextChange} appId={this.props.appId}/>

            // <div className="ui container">
            //     <div className="ui grid">
            //         <div className="sixteen wide mobile twelve wide computer centered column">
            //             <Contract handleFormSubmit={this.props.handleFormSubmit} handleHeaderTextChange={this.props.handleHeaderTextChange}/>
            //         </div>
            //     </div>
            // </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    // return { user: state.users.find(user => user.id === ownProps.userId) }
    return {}
}

export default connect(
    mapStateToProps, 
    {
        handleFormSubmit,
        handleHeaderTextChange,
    }
)(index)