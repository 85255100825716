import _ from 'lodash'

import { 
    NEXT_PAGE, 
    PREV_PAGE, 
    FETCH_USER,
    FETCH_DATA, 
    DELETE_DATA,
    POST_DATA,
    FORM_DATA,
    STORE_USER_INFO,
    ACCOUNT_INFO,
    AUTH_CHECK, 
    FORM_CHECK,
    STORE_ACH,
    STORE_CC,
    HEADER_TEXT,
    SECTION_PROGRESS,
    SECTION_STATUS,
    CHANGE_LOADED,
} from './types'
import jsonPlaceholder from '../dummyData/jsonPlaceholder'
import history from '../history'

// jsonPlaceholder.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

export const handleHeaderTextChange = (bigCopy, smallCopy, includeProgressBar=false) => {
    return {
        type: HEADER_TEXT,
        payload: {
            bigCopy,
            smallCopy,
            includeProgressBar,
        }
    }
}

export const showSchedule = () => {
    return (dispatch, getState) => {
        console.log(getState().pages)
    }
}

export const storeACHCreds = (token, metadata) => {
    // send token to client server
    console.log('success')
    console.log(metadata)
    return {
        type: STORE_ACH,
        payload: {
            token,
            metadata
        }
    }
}
export const toggleACH = (bool) => {
    // send token to client server
    console.log('success')
    // console.log(metadata)
    return {
        type: STORE_ACH,
        payload: {
            token: bool,
            metadata: bool
        }
    }
}
export const storeCCCreds = (token) => {
    // send token to client server
    console.log('success')
    console.log(token)
    return {
        type: STORE_CC,
        payload: {
            token,
        }
    }
}

export const handleFormSubmit = (formVals, form=null) => {
    return async (dispatch, getState) => {
        console.log('handleForm')
        console.log(formVals)
        let stateAppId = getState().users.appId
        let localAppId = localStorage.getItem("app_id")
        let appId = stateAppId || localAppId;
        if (formVals) {
            formVals["app_id"] = appId

            if (form === "billingInfo") {
                if (formVals["billing_option"] === "Credit Card") {
                    formVals["token"] = getState().tokens.ccToken.token
                    // console.log(formVals)
                }
            } else if (form === 'servicesExisting') {
                let newFormVals = mutateServicesData(formVals)
                formVals = {
                    app_id: appId,
                    credentials: newFormVals
                }
                // console.log("services ", formVals)
            }
        } else if (form === "contract") {
            formVals = {
                "app_id": appId,
                "contract_signed": true
            }
        } else {
            formVals = {
                "app_id": appId,
            }
            formVals[form] = "no data"
        }
        let response;

        // let api = {
        //     "restoContactInfo": "flow/account/contact",
        //     "restoInfo": "flow/account/rest_info",
        //     "restoLocation": "flow/account/location",
        //     "restoInstallationContact": "flow/account/install_contact",
        //     "contract":"flow/account/contract",
        // };
        // let apiSFIntegre = {
        //     "restoInfo": "sjjgjcueawu6m7ml8q4418n0qrzsi5cb",
        //     "billingInfo": "y2gdajc41vixw98ray4kt2347dmrowlm",
        //     "servicesExisting": "u1a295d5nhgnrihpdqx6vq780yp6qpbm",
        // };
        let apiSF = {
            "restoInfo": "account",
            "contract": "contract",
            "billingInfo": "billing",
            "servicesExisting": "services",
            "servicesNew": "services",
            "help":"help",
        };

        try {
            // response = await jsonPlaceholder.post(`/api/v1/${api[form]}`, formVals)
            // response = await jsonPlaceholder.post(`/${apiSFIntegre}`, formVals)
            response = await jsonPlaceholder.post(`/onboarding/${apiSF[form]}`, formVals)
        } catch(e) {
            response = ""
            console.log(e)
        }

        if (response.data) {
            // console.log('response data')
            console.log(response.data)
            dispatch({type: FORM_DATA, payload: response.data})
        }

        dispatch(writeFormValsToLocal(formVals))
    }
}

export const writeFormValsToLocal = (formVals) => {
    return dispatch => {
        for (let key in formVals) {
            if (!key.includes("cc_") && !key.includes("ach_")) {
                localStorage.setItem(key, formVals[key])
            }
        }
    }
}

export const mutateServicesData = (formVals) => {
    let formValsCopy = {...formVals};
    let oosNamesArr = Object.keys(formValsCopy);
    let newFormVals = [];
    // let oosObj = {};

    let oosNamesNoAppid = oosNamesArr.filter(key => {
        return key !== 'app_id'
    })

    oosNamesNoAppid.reduce((accum, el, idx, arr) => {
        // console.log('oosNamesNoAppid', el)
        let splitEl = el.split('_');
        let oosName = splitEl[0]
        let value = splitEl[splitEl.length-1]
        // console.log('oosName', oosName)

        if (!Object.keys(accum).length) {
            accum['oos'] = oosName;
            accum[value] = formVals[el];
        } else if (accum['oos'] === oosName) {
            // console.log('in else if mutate', el)
            accum[value] = formVals[el];
        } else {
            newFormVals.push(accum);

            accum = {};
            accum['oos'] = oosName
            accum[value] = formVals[el]
            // console.log('in else mutate', accum)
        }

        if (idx === arr.length-1) {
            newFormVals.push(accum)
        }

        return accum 
    }, {})
    
    // console.log('newFormVals', newFormVals)
    return newFormVals;
}

export const handleEndOfSection = (endOfSection=false, sectionName=null, appId) => {

    return async dispatch => {
    

        if (endOfSection === true && sectionName !== null) {
            if (sectionName === "servicesExisting") {
                console.log("hey there")
                dispatch({type: FORM_CHECK, payload: "calendly"})
                localStorage.setItem("calendly", "true")
            }

            if (sectionName === "servicesNew" || sectionName === "servicesExisting") {
                sectionName="services"
            }
            
            dispatch({type: FORM_CHECK, payload: sectionName})
            dispatch(postEndSection(appId, sectionName))
            localStorage.setItem(sectionName, true)
        }

        let paths = {
            "account": "contract",
            "contract": "billing",
            "billing": "services",
            "services": `?app_id=${appId}`
        }

        // history.push(`/?app_id=${appId}`)
        history.push(`/${paths[sectionName]}`)
    }
}

export const postEndSection = (appId, section) => {
    return async dispatch => {
        console.log("in post end")
        let response
        let reqBody = {}
        reqBody["app_id"] = appId
        reqBody[section] = true

        if (section === 'servicesExisting') {
            reqBody.calendly = true
        }

        try {
            response = await jsonPlaceholder.post(`/onboarding/status`, reqBody)
        } catch(e) {
            console.log(e)
        }
    }
}

export const getSectionData = appId => {
    return async dispatch => {
        let response

        try {
            response = await jsonPlaceholder.get(`/onboarding/status/?app_id=${appId}`)
        } catch(e) {
            console.log (e)
        }

        if (response && response.data) {
            console.log("section data")
            console.log(response.data)

            for (let key in response.data) {
                localStorage.setItem(key, response.data[key])
            }
            dispatch({type: SECTION_STATUS, payload: response.data})
        }
    }
}

export const handleSectionProgress = progressVal => {
    return (dispatch, getState) => {
        let payloadVal = null;
        let progressValInt = parseInt(progressVal)
        let currProgress = getState().header.progressValue
        let currProgressInt = parseInt(getState().header.progressValue)
        console.log("handleProgressAction: ", progressVal);

        payloadVal = progressValInt > currProgressInt ? progressVal : currProgress
        console.log("currProg: ", payloadVal);

        dispatch({type: SECTION_PROGRESS, payload: payloadVal})
    }
}

export const getUserData = (appId, form) => {
    return async dispatch => {
        console.log('getting data')
        let response;
        let apiSF = {
            "restoInfo": "account",
            "billingInfo": "billing",
            "contract": "contract",
            "servicesExisting": "services",
            "servicesNew": "services",
        };

        try {
            response = await jsonPlaceholder.get(`/onboarding/${apiSF[form]}/?app_id=${appId}`)
        } catch(e) {
            console.log(e)
        }

        if (response && response.data) {
            // console.log('get data')
            console.log(response.data) 

            if (form === "restoInfo") {
                dispatch(storeAcctInfo(response.data))
            }
        }
    }
}

export const storeAppId = (appId) => {
    return async dispatch => {
        let savedAppId = localStorage.getItem("app_id")
        console.log("savedAppId", savedAppId)
        if (!savedAppId || (appId && savedAppId !== appId)) {
            console.log("no app_id saved")
            localStorage.clear()

            dispatch(verifyAppId(appId));
        } else if (savedAppId) {
            console.log("savedAppId", savedAppId)
            dispatch(changeIsVerified(true))
            dispatch({type: AUTH_CHECK, payload: savedAppId})
        }
    }
}
export const storeUserInfo = (data=null) => {
    return dispatch => {
        let userInfo = {
            "salesRepresentative": null,
            "salesRepresentative_email": null,
            "salesRepresentative_phone": null,
            "pricing": null,
            "planCode": null,
        }
        if (!data) {
            for (let el in userInfo) {
                userInfo[el] = localStorage.getItem(el)
            }
        } else {
            for (let key in data) {
                let value = data[key]
                if (localStorage.getItem(key) === null || localStorage.getItem(key) !== value) {
                    localStorage.setItem(key, value)
                    userInfo[key] = value
                } else {
                    userInfo[key] = localStorage.getItem(key)
                }
            }
        }
        
        dispatch({type: STORE_USER_INFO, payload: userInfo})
    }
}

export const changeIsVerified = bool => {
    return dispatch => {
        dispatch({type: CHANGE_LOADED, payload: bool})
    }
}

export const storeAcctInfo = (data) => {
    return async dispatch => {
        for (let key in data) {
            let value = data[key]
            localStorage.setItem(key, value)
        }
        dispatch({type: ACCOUNT_INFO, payload: data})
    }
}

export const verifyAppId = (appId) => {
    return async dispatch => {
        let response

        try {
            // response = await jsonPlaceholder.post('api/v1/flow/verify_app_id', reqBody);
            response = await jsonPlaceholder.get(`/onboarding/verify/?app_id=${appId}`);
        } catch(e) {
            console.log(e)
        }

        if (response && response.data) {
            console.log(response.data)
            localStorage.setItem("app_id", appId)

            dispatch({type: AUTH_CHECK, payload: appId})
            dispatch(storeUserInfo(response.data))
            dispatch(getSectionData(appId))
            dispatch(changeIsVerified(true))

            // history.push('/account')
            // dispatch({type: STORE_USER_INFO, payload: response.data})
        } else {
            dispatch(changeIsVerified(false))
            history.push("/error")
        }
    }
}

// FOR WHEN JWT IS AVAILABLE
// export const handleAuth = (tokenOrHash) => {
//     return async dispatch => {
//         let token = localStorage.getItem('token')

//         if (token) {
//             dispatch({type: AUTH_CHECK, payload: token})
//         } else {
//             const backendURL = 'someURL'
//             const response = await backendURL.get('/auth/', tokenOrHash)

//             if (response.data) {
//                 localStorage.setItem("token", response.data.token)
//                 dispatch({type: AUTH_CHECK, payload: response.data.token})
//             } else {
//                 history.push('/')
//             }
//         }
//     }
// }

// export const handleLogin = (formVals) => {
//     return async dispatch => {
//         console.log(formVals)
//         // const backendURL = 'someURL'
//         // const response = await backendURL.post('/auth/', formVals)
        
//         // dispatch({type: AUTH_CHECK, payload: response.data})
//         dispatch({type: AUTH_CHECK, payload: true})
//         history.push('/')
//     }
// }



//sample stuff 

// //combining action creators
// export const fetchDataAndUsers = () => {
//     return async (dispatch, getState) => {
//         await dispatch(fetchData())
        
//         //return array of unique ids after dispatching fetchData and updating the dummy state
//         const userIds = _.uniq(_.map(getState().dummy, 'userId'));
//         userIds.forEach(id => dispatch(fetchUser(id)))
//     }
// }

// export const fetchData = () => {
//     return async dispatch => {
//         const response = await jsonPlaceholder.get(`/posts`)

//         dispatch({type: FETCH_DATA, payload: response.data})
//     }
// }

// export const postData = params => {
//     return async dispatch => {
//         const response = await jsonPlaceholder.post(`/posts`, params)

//         dispatch({type: POST_DATA, payload: response.data})
//     }
// }

// export const deleteData = id => {
//     return async dispatch => {
//         await jsonPlaceholder.delete(`/posts/${id}`)
        
//         dispatch({type: DELETE_DATA, payload: id})
//     }
// }

// export const fetchUser = (id) => {
//     return async dispatch => {
//         const response = await jsonPlaceholder.get(`/users/${id}`)

//         dispatch({type: FETCH_USER, payload: response.data})
//     }
// }

// export const paginate = (backOrNext,first,last) => {
//     let actionType;
//     if (!first && !last) {
//         actionType = backOrNext === 'next' ? NEXT_PAGE : PREV_PAGE
//     }
    
//     return {
//         type: actionType
//     }
// }