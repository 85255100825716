import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector, getFormValues, change } from 'redux-form'
// import PropTypes from 'prop-types'

import Blueprint from '../Partials/Blueprint'
import { renderField, renderCheckbox, renderRadio } from '../Partials/forms'
import normalizePhone from '../Partials/forms/normalizePhone'
import { required, letters, isEmail } from '../Partials/forms/validate'


class InstallationContact extends PureComponent {
    // static propTypes = {

    // }
    state = { ownerCheckbox: this.props.ownerCheckbox || JSON.parse(localStorage.getItem("installation_info_checkbox"))}
    
    componentDidMount() {
        this.props.handleHeaderTextChange("Step 1 - Setup Your Account",null,true);
        this.props.handleSectionProgress("100");
        if (this.props.ownerCheckbox === true) {
            this.copyAccountInfo()
        }
    }

    componentDidUpdate(prevProps) {
        // let ownerCheckbox = this.props.ownerCheckbox || JSON.parse(localStorage.getItem("installation_info_checkbox"))
        this.props.change('installation_contact_method', 'Email')
        
        this.copyAccountInfo()

        // if (this.props.ownerCheckbox === true) {
        //     this.copyAccountInfo()
        // }
    }

    copyAccountInfo = () => {
        if (this.props.ownerCheckbox === true) {
            this.props.change('installation_first_name', this.props.formVals.contact_first_name || localStorage.getItem("contact_first_name"))
            this.props.change('installation_last_name', this.props.formVals.contact_last_name || localStorage.getItem("contact_last_name"))
            this.props.change('installation_email', this.props.formVals.contact_email || localStorage.getItem("contact_email"))
            this.props.change('installation_phone', this.props.formVals.contact_phone || localStorage.getItem("contact_phone"))

            console.log("changee", this.state.ownerCheckbox)
        } else if (this.props.ownerCheckbox === false) {
            this.props.change('installation_first_name', '')
            this.props.change('installation_last_name', '')
            this.props.change('installation_email', '')
            this.props.change('installation_phone', '')
        }
    }

    render() {
        return (
            <Blueprint
                // title="Account Creation"
                // description="Get started with some basic contact information about you and your restaurant."
                section="account"
                endOfSection={true}
                nextText="Continue"
                prevPage="location"
                // form="restoInstallationContact"
                form="restoInfo"
                valid={this.props.valid}
            >

                <div>
                    <h3>Who should we work with on any Technical or Installation details?</h3>
                    <h4>* = required</h4>

                    <form className="ui form">
                        <div>
                            <Field
                                name="installation_info_checkbox"
                                component={renderCheckbox}
                                label="Same info as the Account Owner"
                            />

                            <div className="price-plan">
                                <div className="two fields">
                                    <Field
                                        name="installation_first_name"
                                        component={renderField}
                                        validate={[required, letters]}
                                        placeholder="First Name"
                                        label="First Name *"
                                        type="text"
                                        classes=""
                                    />
                                    <Field
                                        name="installation_last_name"
                                        component={renderField}
                                        validate={[required, letters]}
                                        placeholder="Last Name "
                                        label="Last Name *"
                                        type="text"
                                        classes=""
                                    />
                                </div>
                                <Field
                                    name="installation_email"
                                    component={renderField}
                                    validate={[required, isEmail]}
                                    placeholder="Email"
                                    label="Email *"
                                    type="text"
                                    classes=""
                                />
                                <Field
                                    name="installation_phone"
                                    component={renderField}
                                    validate={[required]}
                                    normalize={normalizePhone}
                                    placeholder="Phone Number"
                                    label="Phone Number *"
                                    type="text"
                                    classes="ten wide"
                                />
                                <div className="ui grid" style={{display: "none"}}>
                                <p>How do you prefer we communicate with the technical contact?</p>
                                    <div className="eight wide column">
                                        <Field
                                            name="installation_contact_method"
                                            component={renderRadio}
                                            validate={required}
                                            label="Email"
                                            radioValue="Email"
                                        />
                                    </div>
                                    <div className="eight wide column">
                                        <Field
                                            name="installation_contact_method"
                                            component={renderRadio}
                                            validate={required}
                                            label="Phone"
                                            radioValue="Phone"
                                        />
                                    </div>
                                </div>
                            </div>
                            
                            {/* {
                                !this.props.ownerCheckbox
                                ?
                                <div className="price-plan">
                                    <div className="two fields">
                                        <Field
                                            name="installation_first_name"
                                            component={renderField}
                                            validate={[required, letters]}
                                            placeholder="First Name"
                                            label="First Name"
                                            type="text"
                                            classes=""
                                        />
                                        <Field
                                            name="installation_last_name"
                                            component={renderField}
                                            validate={[required, letters]}
                                            placeholder="Last Name"
                                            label="Last Name"
                                            type="text"
                                            classes=""
                                        />
                                    </div>
                                    <Field
                                        name="installation_email"
                                        component={renderField}
                                        validate={[required, isEmail]}
                                        placeholder="Email"
                                        label="Email"
                                        type="text"
                                        classes=""
                                    />
                                    <Field
                                        name="installation_phone"
                                        component={renderField}
                                        validate={[required]}
                                        normalize={normalizePhone}
                                        placeholder="Phone Number"
                                        label="Phone Number"
                                        type="text"
                                        classes="ten wide"
                                    />
                                    <p>How do you prefer we communicate with the technical contact?</p>
                                    <div className="ui grid">
                                        <div className="eight wide column">
                                            <Field
                                                name="installation_contact_method"
                                                component={renderRadio}
                                                validate={required}
                                                label="Email"
                                                radioValue="Email"
                                            />
                                        </div>
                                        <div className="eight wide column">
                                            <Field
                                                name="installation_contact_method"
                                                component={renderRadio}
                                                validate={required}
                                                label="Phone"
                                                radioValue="Phone"
                                            />
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            }                */}
                            
                            {/* <p>How do you prefer we communicate with the technical contact?</p>
                            <div className="ui grid">
                                <div className="eight wide column">
                                    <Field
                                        name="installationContactCommunicationOption"
                                        component={renderRadio}
                                        validate={required}
                                        label="Email"
                                        radioValue="Email"
                                    />
                                </div>
                                <div className="eight wide column">
                                    <Field
                                        name="installationContactCommunicationOption"
                                        component={renderRadio}
                                        validate={required}
                                        label="Phone"
                                        radioValue="Phone"
                                    />
                                </div>
                            </div> */}
                            {/* <div className="three fields">
                                <Field
                                    name="installationContactCommunicationOption"
                                    component={renderRadio}
                                    validate={required}
                                    label="Email"
                                    radioValue="Email"
                                />

                                <Field
                                    name="installationContactCommunicationOption"
                                    component={renderRadio}
                                    validate={required}
                                    label="Phone"
                                    radioValue="Phone"
                                />
                                
                                <Field
                                    name="installationContactCommunicationOption"
                                    component={renderRadio}
                                    label="Text"
                                    radioValue="Text"
                                />
                            </div> */}
                        </div>
                    </form>
                </div>
            </Blueprint>
        )
    }
}


InstallationContact = reduxForm({
    // form: 'restoInstallationContact', // <------ same form name
    form: 'restoInfo', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    // validate
})(InstallationContact)

// const selector = formValueSelector('restoInstallationContact')
const selector = formValueSelector('restoInfo')
const mapStateToProps = (state, ownProps) => {
    // return { user: state.users.find(user => user.id === ownProps.userId) }
    // let ownerCheckbox = selector(state, 'restoInfo')
    let ownerCheckbox = selector(state, 'installation_info_checkbox')
    let formVals = getFormValues('restoInfo')(state)

    return {
        ownerCheckbox,
        formVals,
        change
    }
}

export default connect(
    mapStateToProps, 
    {}
)(InstallationContact)