// import _ from 'lodash'

import { AUTH_CHECK, STORE_USER_INFO, CHANGE_LOADED } from '../actions/types'

const INITIAL_STATE = {
    isVerified: null,
    appId: localStorage.getItem("app_id") || null,
    salesRepresentative: localStorage.getItem("salesRepresentative") || "us",
    salesRepresentativeEmail: localStorage.getItem("salesRepresentative_email") || null,
    salesRepresentativePhone: localStorage.getItem("salesRepresentative_phone") || null,
    pricing: localStorage.getItem("pricing") || null,
    planCode:  localStorage.getItem("planCode") || null,
}

export default (state=INITIAL_STATE, action ) => {
    switch (action.type) {
        case AUTH_CHECK:
            return { ...state, appId: action.payload }
        case STORE_USER_INFO:
            // let {salesRepresentative, pricing, planCode} = action.payload
            // return {...state, salesRepresentative, pricing, planCode}
            return {...state, ...action.payload}
        case CHANGE_LOADED:
            return {...state, isVerified: action.payload}

        // case FETCH_DATA:
        //     return {...state, someKey: action.payload}
        // case DELETE_DATA:
        //     return _.omit(state, action.payload)
        // case FORM_DATA:
        //     return {...state, formData: action.payload}

        default:
            return state
    }
};