import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import dummyReducer from './dummyReducer';
import dummyUserReducer from './dummyUserReducer';
import userReducer from './userReducer';
import pageReducer from './pageReducer';
import tokenReducer from './tokenReducer';
import headerReducer from './headerReducer';
import accountReducer from './accountReducer';

export default combineReducers({
    dummy: dummyReducer,
    dummyUsers: dummyUserReducer,
    users: userReducer,
    pages: pageReducer,
    tokens: tokenReducer,
    header: headerReducer,
    account: accountReducer,
    form: formReducer
})

