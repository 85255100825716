import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { Embed } from 'semantic-ui-react'
// import PropTypes from 'prop-types'

// import Blueprint from '../Partials/Blueprint'
import history from '../../history'

export default class Contract extends PureComponent {
    // static propTypes = {

    // }
    componentDidMount() {
        this.props.handleHeaderTextChange(`All the legal “stuff”.`,null);
        let appId;

        if (this.props.appId) {
            appId = this.props.appId
        } else if (localStorage.getItem("app_id")) {
            appId = localStorage.getItem("app_id")
        }
        console.log("contract appId", this.props.appId)

    }
    // handleSubmitButton = (e) => {
    //     e.preventDefault();
    //     this.props.handleFormSubmit("test", "contract");
    //     history.push('/');
    // }

    render() {
        return (
            // <Blueprint
            //     title="Contract"
            //     description="Sign here please"
            //     nextText="Submit"
            //     section="contract"
            //     handleFormSubmit={this.props.handleFormSubmit}
            //     valid={true}
            // >
            //     <Embed
            //         icon='right circle arrow'
            //         placeholder=''
            //         iframe={{
            //             allowFullScreen: true,
            //             scrolling: "yes",
            //             style: {
            //                 // width: "100%",
            //                 // height: "60vh",
            //             },
            //         }}
            //         url='https://demo.docusign.net/MEMBER/PowerFormSigning.aspx?PowerFormId=
            //         8968380d-16da-447c-97d7-fa989ed11082&Signer%201_UserName=James%20Smith'
            //         // url='http://www.myfav.es/jack'
                    
            //     />
            // </Blueprint>

            <div className="ui container">
                <div className="ui grid">
                    <div className="sixteen wide mobile twelve wide computer centered column">            
                        <div>
                            <Embed
                                icon='right circle arrow'
                                placeholder=''
                                iframe={{
                                    allowFullScreen: true,
                                    scrolling: "yes",
                                    style: {
                                        // width: "100%",
                                        // height: "60vh",
                                    },
                                }}
                                // url='http://www.myfav.es/jack'
                                url={`https://form.jotform.com/91416159977167?app_id=${this.props.appId}`}
                            />
                            <div className="buttonPad">
                                    {/* <button className="circular ui right floated button next-button" onClick={this.handleSubmitButton}>
                                        <span>Submit</span>
                                    </button> */}
                                    <Link to={`/?app_id=${this.props.appId}`}>
                                        <button className="circular ui left floated button back-button">
                                            &lt; Back
                                        </button>
                                    </Link> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
