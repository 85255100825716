import React, { PureComponent } from 'react'
import { Route, Switch, Redirect  } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactGA from 'react-ga'
// import PropTypes from 'prop-types'

import { handleHeaderTextChange, handleSectionProgress, handleFormSubmit, storeAppId, handleEndOfSection, getUserData, storeUserInfo } from '../../actions'
import history from '../../history'
import Header from '../Partials/Header'
import Footer from '../Partials/Footer'
import Home from '../home'
import AccountCreation from '../accountCreation'
import BillingSetup from '../billingSetup'
import Contract from '../contract'
import Services from '../services'
import Help from '../help'
import Error from './Error'
// import Routes from './Routes'
// import equipment from '../equipment'

class index extends PureComponent {
    static propTypes = {

    }
    componentDidMount() {
        ReactGA.pageview(window.location.pathname)
        console.log('route appid', this.props.appId)
        if(this.props.appId) {
            ReactGA.set({ userId: this.props.appId })
        }

        // this.props.getUserData(this.props.appId, 'restoInfo') 
    }

    render() {
        return (
            <div className="full-height-flex">
                <div className="non-footer-flex-content">
                    <Header 
                        headerSmallCopy={this.props.headerSmallCopy}
                        headerBigCopy={this.props.headerBigCopy}
                        includeProgressBar={this.props.includeProgressBar}
                        progressValue={this.props.progressValue}
                        handleSectionProgress={this.props.handleSectionProgress}
                        appId={this.props.appId}
                        accountStatus = {this.props.pages.account}
                    />
                    <Switch>
                        <Route 
                            path="/account"
                            render={(props) => <AccountCreation {...props} status={this.props.pages} appId={this.props.appId}/>}                         
                        />
                        <Route 
                            path="/billing"
                            render={(props) => <BillingSetup {...props} />}
                        />
                        <Route 
                            exact 
                            path="/contract" 
                            render={(props) => <Contract {...props} appId={this.props.appId} /> } 
                        />
                        <Route 
                            path="/services" 
                            render={(props) => <Services {...props} />} 
                        />
                        <Route 
                            path="/Help" 
                            render={(props) => (
                                <Help 
                                    {...props} 
                                    salesRepresentative={this.props.salesRepresentative}
                                    salesRepresentativeEmail={this.props.salesRepresentativeEmail}
                                    salesRepresentativePhone={this.props.salesRepresentativePhone}
                                />
                            )}
                        />
                        {/* <Route path="/equipment" component={equipment} /> */}
                        {/* <Route
                            path="/?app_id=:app_id"
                            render ={() => <Redirect to="/account" />}
                        /> */}
                        <Route 
                            exact 
                            path={"/" || "/?status=complete"} 
                            render={ (props) => (
                                this.props.appId 
                                ?
                                <Home 
                                    {...props} 
                                    status={this.props.pages} 
                                    handleHeaderTextChange={this.props.handleHeaderTextChange}
                                    handleFormSubmit={this.props.handleFormSubmit}
                                    handleEndOfSection={this.props.handleEndOfSection}
                                    appId={this.props.appId}
                                    salesRepresentative={this.props.salesRepresentative}
                                    salesRepresentativeEmail={this.props.salesRepresentativeEmail}
                                    salesRepresentativePhone={this.props.salesRepresentativePhone}
                                    name="John" 
                                />
                                :
                                null
                            )} 
                        />
                        <Route component={Error} />
                    </Switch>
                </div>
                <div>
                    <Footer />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    // return { user: state.users.find(user => user.id === ownProps.userId) }
    return {
        // auth: state.tokens.authToken,
        appId: state.users.appId,
        pages: state.pages,
        headerSmallCopy: state.header.smallCopy,
        headerBigCopy: state.header.bigCopy,
        includeProgressBar: state.header.includeProgressBar,
        progressValue: state.header.progressValue,
        salesRepresentative: state.users.salesRepresentative,
        salesRepresentativeEmail: state.users.salesRepresentativeEmail,
        salesRepresentativePhone: state.users.salesRepresentativePhone,
    }
}

export default connect(
    mapStateToProps, 
    {
        handleHeaderTextChange,
        handleSectionProgress,
        handleFormSubmit,
        storeAppId,
        storeUserInfo,
        handleEndOfSection,
        getUserData, 
    }
)(index)