import React, { PureComponent } from 'react'
// import PropTypes from 'prop-types'

import Loading from './Loading'

export default class index extends PureComponent {
//   static propTypes = {

//   }

    render() {
        return (
            <Loading />
        )
    }
}
