import React, { PureComponent } from 'react'

import Header from './Header'

export default class index extends PureComponent {
    render() {
        return (
            <Header 
                name={this.props.name}
                headerSmallCopy={this.props.headerSmallCopy}
                headerBigCopy={this.props.headerBigCopy}
                includeProgressBar={this.props.includeProgressBar}
                progressValue={this.props.progressValue}
                handleSectionProgress={this.props.handleSectionProgress}
                appId={this.props.appId}
                accountStatus = {this.props.accountStatus}
            />
        )
    }
}
