import React, { PureComponent } from 'react'
// import PropTypes from 'prop-types'

import ProgressBar from './ProgressBar'

export default class index extends PureComponent {
    // static propTypes = {

    // }

    render() {
        return (
            <ProgressBar value={this.props.value} />
        )
    }
}
