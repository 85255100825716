import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Segment, Icon, Header } from 'semantic-ui-react'
// import PropTypes from 'prop-types'

// import ProgressBar from '../ProgressBar'
import BackNextButtonGroup from '../BackNextButtonGroup'

export default class Blueprint extends Component {
    // static propTypes = {
    //     prop: PropTypes
    // }

    renderLink = () => {
        let heightLevel = this.props.tallHeight ? 'tall-height' : "equalize-height"

        //for landing page
        if (this.props.link) {
            return (
                this.props.prevSectionComplete && process.env.NODE_ENV === 'development'
                ?
                <Link to={this.props.link}>
                    <Segment basic className="home">
                    {/* <Segment raised className="home"> */}
                        <Header as="h4">
                            {
                                this.props.done 
                                ? 
                                <Icon name='check' size="big"/>
                                // <Icon name='check circle outline' size="big"/>
                                :
                                <Icon name='circle outline' size="big"/>
                            }
                            <Header.Content>
                                {this.props.title}
                                <Header.Subheader>
                                    {/* {this.props.description} */}
                                    {this.props.children}
                                </Header.Subheader>
                            </Header.Content>
                            {/* { this.props.hideButtons ? null : <BackNextButtonGroup /> } */}
                        </Header>
                    </Segment>
                </Link>
                :
                <Segment basic className="home">
                {/* <Segment raised disabled className="home"> */}
                    <Header as="h4">
                        {
                            this.props.done 
                            ? 
                            <Icon name='check' size="big"/>
                            // <Icon name='check circle outline' size="big"/>
                            :
                            <Icon name='circle outline' size="big"/>
                        }
                        <Header.Content>
                            {this.props.title}
                            <Header.Subheader>
                                {/* {this.props.description} */}
                                {this.props.children}
                            </Header.Subheader>
                        </Header.Content>
                        {/* { this.props.hideButtons ? null : <BackNextButtonGroup /> } */}
                    </Header>
                </Segment>
            )

        //for multiple components in one segment
        } else if (this.props.unsegment) {
            return (
                <Segment.Group raised>
                    <Segment>
                        <Header as="h3">
                            <Icon name='check circle outline' size="big"/>
                            {/* <Icon name='check circle outline' size="big"/> */}
                            <Header.Content>
                                {this.props.title}
                                <Header.Subheader>
                                    {this.props.description}
                                </Header.Subheader>
                            </Header.Content>
                            {/* { this.props.hideButtons ? null : <BackNextButtonGroup /> } */}
                        </Header>
                    </Segment>        
                    {this.props.children}
                    { this.props.hideButtons ? null : <BackNextButtonGroup nextText={this.props.nextText}/> }
                    {/* <div>
                        { this.props.hideButtons ? null : <BackNextButtonGroup /> }
                    </div> */}
                </Segment.Group>
            )
        } else {
            return (
                // non-segments(not-boxed)
                
                <div className={`${heightLevel} seg-wrapper container`}>
                    <div className="content">
                        {this.props.children}
                    </div>
                    <div>
                        { 
                            this.props.hideButtons 
                                ? 
                            null 
                                : 
                            <BackNextButtonGroup 
                                section={this.props.section}
                                nextText={this.props.nextText} 
                                nextPage={this.props.nextPage} 
                                prevPage={this.props.prevPage}
                                // handleFormSubmit={this.props.handleFormSubmit}
                                endOfSection={this.props.endOfSection}
                                form={this.props.form}
                                valid={this.props.valid}
                            /> 
                        }
                    </div>
                </div>

                // as segments(boxed)
                // <Segment.Group className={`${heightLevel} seg-wrapper container`}>
                //     <Segment >
                //         <Header as="h3">
                //             <Icon name='check circle outline' size="big"/>
                //             <Header.Content>
                //                 {this.props.title}
                //                 <Header.Subheader>
                //                     {this.props.description}
                //                 </Header.Subheader>
                //             </Header.Content>
                //             {/* { this.props.hideButtons ? null : <BackNextButtonGroup /> } */}
                //         </Header>
                //     </Segment>
                //     <Segment className="content">
                //         {this.props.children}
                //     </Segment>
                //     { this.props.hideButtons ? null : <BackNextButtonGroup nextText={this.props.nextText}/> }
                // </Segment.Group>
            )
        }
    }

    render() {
        return (
            <div className="ui stackable centered grid container">
                <div className="row stretched">
                    {/* <div className="sixteen wide mobile nine wide tablet nine wide computer column"> */}
                    <div className="ten wide computer column">
                    {/* <div className="nine wide computer column"> */}
                        {this.renderLink()}
                    </div>
                </div>
            </div>
        )
    }
}