import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { formValueSelector, getFormValues, change } from 'redux-form'
import {handleFormSubmit, handleEndOfSection} from '../../../actions'
// import { injectStripe } from 'react-stripe-elements';
// import PropTypes from 'prop-types'

import BackNextButtonGroup from './BackNextButtonGroup'

class index extends PureComponent {
    // static propTypes = {

    // }
    // validateNext = () => {
    //     console.log('forms')
    //     console.log(this.props.formVals)
    // }


    render() {
        // console.log(this.props)
        console.log(this.props.form)
        return (
            <BackNextButtonGroup 
                nextText={this.props.nextText} 
                section={this.props.section} 
                nextPage={this.props.nextPage} 
                prevPage={this.props.prevPage} 
                formVals={this.props.formVals}
                handleEndOfSection ={this.props.handleEndOfSection}
                handleFormSubmit={this.props.handleFormSubmit}
                currSect={this.props.currSect}
                valid={this.props.valid}
                form={this.props.form}
                endOfSection={this.props.endOfSection}
                appId={this.props.appId}
            />
        )
    }
}

const selector = formValueSelector('restoInfo')
const mapStateToProps = (state, ownProps) => {
    // return { user: state.users.find(user => user.id === ownProps.userId) }
    // formVals = selector(state, 'contactName', 'contactEmail')

    let formVals = getFormValues(ownProps.form)(state)

    return { 
        appId: state.users.appId,
        currSect: state.pages.accountCreation,
        formVals,
        // formVals: getFormValues('restoContactInfo')(state)
    }
}

index = connect(
    mapStateToProps,
    {
        handleFormSubmit,
        handleEndOfSection,
        change,
    }
)(index)

export default index