import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactGA from 'react-ga'


// import {StripeProvider} from 'react-stripe-elements'

import './App.scss';
import history from './history'
import { storeAppId, storeUserInfo, getUserData } from './actions'
import Loading from './components/Partials/Loading'
import routes from './components/routes'
import Error from './components/routes/Error'
import {PUBLIC_KEY} from './config'
// import login from './components/login'

history.listen((location, action) => {
    ReactGA.set({ page: location.pathname })
	ReactGA.pageview(location.pathname)
    // ReactGA.pageview(location.pathname + location.search);
})
class App extends Component {
    componentDidMount() {
        // ReactGA.pageview(window.location.pathname)
        console.log('app mount', this.props.appId)
        console.log("env is", process.env.NODE_ENV)
        let parsedUrl = new URL(window.location.href);
        let qs = parsedUrl.searchParams.get("app_id")

        if (this.props.appId && qs === null) {
            qs = this.props.appId
        }
        // let qs = this.props.match.app_id
        // let pathname = this.props.location.pathname.slice(1)
        // console.log(pathname)
        if(this.props.appId) {
            ReactGA.set({ userId: this.props.appId })
        }
        
        this.props.storeAppId(qs)
        this.props.getUserData(qs, "restoInfo")
        this.props.getUserData(qs, "contract")
    }

    loadApp = () => {
        if (this.props.isVerified) {
            return (
                <Router history={history}>
                    <Switch>
                        <Route component={routes} appId={this.props.appId}/>
                    </Switch>
                </Router>
            )
        } else if (this.props.isVerified === null) {
            return (
                <div className="vert-center-parent">
                    <Loading />
                </div>
            )
        } else {
            return (
                <Error />
            );
        }
    }

    render() {
        return (
            <div className="App">
                {/* <Router history={history}>
                    <Switch>
                        <Route path="/login" component={login} />
                        <Route path={"/" || "/?app_id=:app_id"} component={routes} />
                        <Route component={routes} appId={this.props.appId}/>
                    </Switch>
                </Router> */}

                {this.loadApp()}
            </div>

        );
    }
}

const mapStateToProps = (state, ownProps) => {
    // return { user: state.users.find(user => user.id === ownProps.userId) }
    return {
        appId: state.users.appId,
        isVerified: state.users.isVerified,
    }
}

export default connect(
    mapStateToProps, 
    {
        storeAppId,
        storeUserInfo,
        getUserData
    }
)(App)
