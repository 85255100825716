import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
// import { Divider } from 'semantic-ui-react'
// import PropTypes from 'prop-types'

import ProgressBar from '../ProgressBar'

export default class Header extends PureComponent {
    static propTypes = {

    }

    // componentDidMount() {
    // }
    // componentDidUpdate(prevProps) {
    //     if (this.props.progessValue !== prevProps.progessValue) {
    //         this.props.handleSectionProgress();
    //     }
    // }
    // shouldComponentUpdate() {

    // }

    render() {
        console.log("header progress: ", this.props.progressValue)

        return (
            // <div className="ui massive menu"> 
            //     <h2>{this.props.headerBigCopy}</h2>
            // </div>

            <div className="header-bg" style={{marginBottom: "20px", paddingTop: "1em"}}>
                <div className="ui grid fluid container">
                    <div className="row header-row">
                        <div className="four wide mobile two wide tablet two wide computer right floated right aligned column">
                            {
                                process.env.NODE_ENV === 'development'
                                ?
                                <h4><Link to={`/?app_id=${this.props.appId}`}>home</Link></h4>
                                :
                                null
                            }
                        </div>
                    </div>
                    <div className="row header-row-no-top-space">
                        <div className="column">
                            <div style={{textAlign: "center"}}>
                                <h2 className="header-h2-margin">{this.props.headerBigCopy}</h2>
                                <p>{this.props.headerSmallCopy}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}