import React, { PureComponent } from 'react'
// import _ from 'lodash'
// import { Link } from 'react-router-dom'
// import PropTypes from 'prop-types'

import history from '../../history'
import Blueprint from '../Partials/Blueprint'
import HelpSegment from './HelpSegment'
// import Calendly from '../Partials/Calendly'

export default class index extends PureComponent {
    // static propTypes = {

    // }
    constructor(props) {
        super(props)
        
        this.state = {
            showCal: false
        }
    }

    componentDidMount() {
        this.props.handleHeaderTextChange(`You're All Set! Welcome to Ordermark.`, null);
        // this.props.handleHeaderTextChange(`Hi ${this.props.name}, welcome to Ordermark!`,null);
        console.log(this.props.location.search)

        if (this.props.location.search === "?status=complete") {
            this.handleContractCompletion(this.props.appId)
        }
    }

    handleContractCompletion = async (appId) => {
        //after hooked up to BE
        // try {
        //     await this.props.handleFormSubmit({"contract": "complete"}, "contract")
        //     await this.props.handleEndOfSection(true, "contract")
        // } catch(e) {
        //     console.log(e)
        console.log(appId)

        try {
            await this.props.handleFormSubmit({"contract": "complete"}, "contract")
            await this.props.handleEndOfSection(true, "contract", appId)
        } catch(e) {
            console.log(e)
        } finally {
            // history.push(`/`)
            history.push(`/?app_id=${appId}`)
        }
    }

    toggleCal = () => {
        console.log(`showCal ${this.state.showCal}`)
        this.setState({showCal: !this.state.showCal})
        console.log(`showCal after state change ${this.state.showCal}`)
    }

    renderComplete = () => {
        let completionStatus = this.props.status
        let isCalendlyComplete = completionStatus.calendly
        let {calendly, ...completionStatusNoCalendly} = completionStatus
        let isComplete;

        for (let key in completionStatusNoCalendly) {
            if (!completionStatusNoCalendly[key] && !JSON.parse(localStorage.getItem(key))) {
                isComplete = false
                break
            }

            isComplete = true
        }
        console.log(isComplete)

        if (isComplete === true && isCalendlyComplete === true) {
            return (
                <div className="schedule-section">
                    {/* <h3>You are all set! We will reach out to you at {localStorage.getItem("contact_email")} for next steps</h3> */}
                    <div>
                        <h3>
                            We’re excited to get you up and running and are preparing your equipment for shipment now.
                        </h3>

                        <p>
                            After receiving your equipment simply follow the provided setup instructions and give us a call to activate your system.
                            {/* After receiving your equipment simply follow the <span style={{textDecoration: "underline"}}>setup instructions</span> and give us a call to activate your system. */}
                        </p>
                    </div>

                    <div style={{marginTop: "4rem"}}>
                        <HelpSegment  
                            salesRepresentative ={this.props.salesRepresentative}
                            salesRepresentativeEmail={this.props.salesRepresentativeEmail}
                            salesRepresentativePhone={this.props.salesRepresentativePhone} 
                        />
                        {/* <h4>
                            If you have any questions or concerns please let me know at [Rep Email] or call [Rep Phone Number] <br />
                            - {this.props.salesRepresentative} 
                        </h4> */}
                    </div>
                </div>
            )
        } else if (isComplete === true && isCalendlyComplete === false) {
            return (
                <div className="schedule-section">
                    <div>
                        <h3>
                            We’re excited to get you up and running. We will reach out to you at {localStorage.getItem("contact_email")} about signing up for new Online Ordering Services!
                        </h3>
                    </div>

                    <div style={{marginTop: "4rem"}}>
                        <HelpSegment  
                            salesRepresentative ={this.props.salesRepresentative}
                            salesRepresentativeEmail={this.props.salesRepresentativeEmail}
                            salesRepresentativePhone={this.props.salesRepresentativePhone}
                        />
                        {/* <h4>
                            If you have any questions or concerns please let me know at [Rep Email] or call [Rep Phone Number] <br />
                            - {this.props.salesRepresentative}
                        </h4> */}
                    </div>
                </div>
            )
        } else {
            return null;
        }

        // return (
        //     isComplete 
        //         ?
                    // <div className="schedule-section">
                    //     <h3>You are all set! You can now schedule your One-On-One Activation Call. We will reach out to you soon</h3>
                    //     <button className="circular ui button next-button" onClick={this.toggleCal}>Schedule Now</button>
                    //     { this.state.showCal ? <Calendly toggleCal={this.toggleCal} showCal={this.state.showCal} appId={this.props.appId}/> : null }
                    // </div>
        //         :
        //             null
        // )
    }

    render() {
        return (
            <div className="ui center aligned two column centered grid container">
            {/* <div className="ui center aligned container"> */}

                <div className="column">
                    <div>
                        <Blueprint
                            title="Account and Restaurant Setup"
                            description="Get started with some basic information about you and your restaurant."
                            // link="/account"
                            link="/account"
                            hideButtons={true}
                            done={this.props.status.account}
                            prevSectionComplete = {true}
                        />
                    </div>
                    <div>    
                        <Blueprint 
                            title="Restaurant Service Agreement"
                            description="Review and sign your agreement."
                            link="/contract"
                            hideButtons={true}
                            done={this.props.status.contract}
                            prevSectionComplete = {this.props.status.account}
                        />
                    </div>
                    <div>    
                        <Blueprint 
                            title="Billing Setup"
                            description="Configure your preferred secure payment option (Credit Card or ACH)."
                            link="/billing"
                            hideButtons={true}
                            done={this.props.status.billing}
                            prevSectionComplete = {this.props.status.contract}
                        />
                    </div>

                    <div>    
                        <Blueprint 
                            title="Services"
                            description="Connect your existing online ordering services or request help with new ones."
                            hideButtons={true}
                            link="/services"
                            done={this.props.status.services}
                            prevSectionComplete = {this.props.status.billing}
                        />
                    </div>
                </div>

                {this.renderComplete()}
            </div>
        )
    }
}
