import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Switch, Route} from 'react-router-dom'
// import PropTypes from 'prop-types'

import { handleFormSubmit, handleHeaderTextChange } from '../../actions'
import PaymentOptions from './PaymentOptions'
// import ACHPaymentOption from './ACHPaymentOption'


class index extends Component {
    // static propTypes = {
    //     prop: PropTypes
    // }

    // reduxFunction = () => {
    //     this.props.selectSong()
    // }
    
    render() {
        return (
            <Switch>
                <Route 
                    eaxct 
                    path="/billing" 
                    render = {(props) => (
                        <PaymentOptions 
                            {...props}  
                            handleFormSubmit={this.props.handleFormSubmit} 
                            ACHToken={this.props.ACHToken}
                            ACHMetadata={this.props.ACHMetadata}
                            handleHeaderTextChange={this.props.handleHeaderTextChange}
                        />
                    )}
                />
            </Switch>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    // return { user: state.users.find(user => user.id === ownProps.userId) }
    return {
        ACHToken: state.tokens.ACHToken,
        ACHMetadata: state.tokens.ACHMetadata,
    }
}

export default connect(
    mapStateToProps, 
    {
        handleFormSubmit,
        handleHeaderTextChange,
    }
)(index)