import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { formValueSelector, getFormValues } from 'redux-form'
// import PropTypes from 'prop-types'

import { handleFormSubmit, handleHeaderTextChange } from '../../actions'
import ServicesLanding from './ServicesLanding'
import NewServices from  './NewServices'
import ServicesExisting from './ServicesExisting'
import ServicesConfirmation from './ServicesConfirmation'

class index extends Component {
    // static propTypes = {
    //     prop: PropTypes
    // }

    // reduxFunction = () => {
    //     this.props.selectSong()
    // }
    
    render() {
        return (
            <Switch>
                <Route 
                    eaxct 
                    path="/services/new"
                    render = {(props) => (
                        <NewServices 
                            {...props} 
                            handleFormSubmit={this.props.handleFormSubmit} 
                            handleHeaderTextChange={this.props.handleHeaderTextChange} 
                            ownerCheckbox={this.props.formVals}
                        />
                    )}
                />
                <Route 
                    eaxct 
                    path="/services/existing"
                    render = {(props) => (
                        <ServicesExisting 
                            {...props} 
                            handleFormSubmit={this.props.handleFormSubmit} 
                            handleHeaderTextChange={this.props.handleHeaderTextChange}
                        />
                    )}                                        
                />
                <Route 
                    eaxct 
                    path="/services/confirm" 
                    render = {(props) => (
                        <ServicesConfirmation 
                            {...props} 
                            handleFormSubmit={this.props.handleFormSubmit} 
                            handleHeaderTextChange={this.props.handleHeaderTextChange}
                            restFormVals={this.props.restFormVals}
                        />
                    )}
                />
                <Route 
                    path="/services"
                    render = {(props) => (
                        <ServicesLanding 
                            {...props} 
                            handleFormSubmit={this.props.handleFormSubmit} 
                            handleHeaderTextChange={this.props.handleHeaderTextChange}
                        />
                    )}
                />
            </Switch>
        )
    }
}

const selector = formValueSelector('services')
// index = connect(state => ({
//     formVals: selector(state, 'serviceOwnerBox')
// }))(index)

const mapStateToProps = (state, ownProps) => {
    // return { user: state.users.find(user => user.id === ownProps.userId) }
    let ownerCheckbox = selector(state, 'serviceOwnerBox')
    let restFormVals = getFormValues('restoInfo')(state)

    return {
        ownerCheckbox,
        restFormVals
    }
}

export default connect(
    mapStateToProps, 
    {
        handleFormSubmit,
        handleHeaderTextChange,
    }
)(index)