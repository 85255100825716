import React, { PureComponent } from 'react'
import { Field, reduxForm, formValueSelector, getFormValues, change } from 'redux-form'
import { connect } from 'react-redux'
// import PropTypes from 'prop-types'

import { renderField, renderSelector, renderCheckbox, renderRadio, } from '../Partials/forms'
// import normalizePhone from '../Partials/forms/normalizePhone'
import Blueprint from '../Partials/Blueprint'
import { states } from '../../dummyData/constants'
import { required } from '../Partials/forms/validate'
import ShippingDetails from './ShippingDetails'
import normalizeZip from '../Partials/forms/normalizeZip';


class RestaurantLocationv2 extends PureComponent {
    // static propTypes = {

    // }
    componentDidMount() {
        console.log('loading')
        this.props.change('location_shipment_option', true)
    }

    componentDidUpdate(prevProps) {
        this.copyLocationInfoToShipping(prevProps)

    }

    copyLocationInfoToShipping = (prevProps) => {
        if (this.props.shipmentCheckbox === true) {
            this.props.change('shipping_address1', this.props.locationVals.location_address1)
            this.props.change('shipping_address2', this.props.locationVals.location_address2)
            this.props.change('shipping_city', this.props.locationVals.location_city)
            this.props.change('shipping_state', this.props.locationVals.location_state)
            this.props.change('shipping_zip', this.props.locationVals.location_zip)
        } else if (this.props.shipmentCheckbox === false && (prevProps.shipmentCheckbox !== this.props.shipmentCheckbox)) {
            this.props.change('shipping_address1', '')
            this.props.change('shipping_address2', '')
            this.props.change('shipping_city', '')
            this.props.change('shipping_state', '')
            this.props.change('shipping_zip', '')
        }
        console.log("ship changee", this.props.shipmentCheckbox )
    }

    render() {
        return (
            <div className="divide-top-no-divider">
                <h3>Where is your Restaurant located?</h3>

                <div>
                    <form className="ui form">
                        {/* <Field
                            name="resto_store_number"
                            component={renderField}
                            // validate={required}
                            placeholder="Store Number(if applicable)"
                            type="text"
                            classes="ten wide"
                        /> */}
                        {/* <Field
                            name="location_phone"
                            component={renderField}
                            validate={required}
                            normalize={normalizePhone}
                            placeholder="Restaurant Phone Number"
                            label="Restaurant Phone Number *"
                            type="text"
                            classes="ten wide"
                        /> */}
                        <Field
                            name="location_address1"
                            component={renderField}
                            validate={required}
                            placeholder="Address"
                            label="Address *"
                            type="text"
                        />
                        <Field
                            name="location_address2"
                            component={renderField}
                            placeholder="Address"
                            type="text"
                        />
                        <div className="two fields">
                            <Field
                                name="location_city"
                                component={renderField}
                                validate={required}
                                placeholder="City"
                                label="City *"
                                type="text"
                                classes="fourteen wide"
                            />
                            <Field
                                name="location_state"
                                component={renderSelector}
                                validate={required}
                                arr={states}
                                label="State *"
                                selection="State"
                                classes="two-field-selector"
                            />
                        </div>
                        <Field
                            name="location_zip"
                            component={renderField}
                            validate={required}
                            normalize={normalizeZip}
                            placeholder="Zip Code"
                            label="Zip Code *"
                            type="text"
                            classes="six wide"
                        />
                    </form>
                </div>

                <ShippingDetails />
            </div>
        )
    }
}


RestaurantLocationv2 = reduxForm({
    // form: 'restoLocation', // <------ same form name
    form: 'restoInfo', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: false, // <------ unregister fields on unmount
    // validate
})(RestaurantLocationv2)

// const selector = formValueSelector('restoLocation')
const selector = formValueSelector('restoInfo')

RestaurantLocationv2 = connect(state => {
    const shipmentCheckbox = selector(state, 'location_shipment_option')
    let locationVals = getFormValues('restoInfo')(state)
    return {
        shipmentCheckbox,
        locationVals,
        change
    }
})(RestaurantLocationv2)


export default RestaurantLocationv2