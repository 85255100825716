import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { Field , reduxForm } from 'redux-form'
import { Accordion, Icon, Header } from 'semantic-ui-react'
// import { Accordion, Button, Form, Segment, Icon, Header } from 'semantic-ui-react'
// import PropTypes from 'prop-types'

import Blueprint from '../Partials/Blueprint'
import { renderField } from '../Partials/forms'
// import { required } from '../Partials/forms/validate'

class ServicesExisting extends PureComponent {
    // static propTypes = {

    // }
    state = { activeIndex: -1 }

    componentDidMount() {
        this.props.handleHeaderTextChange("Step 4 - Connect Your Services",null);
    }

    handleClick = (e, titleProps) => {
        console.log(titleProps)
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }

    renderOOSList = (OOSArray, activeIndexState, clickFunc) => {
        return OOSArray.map((OOSName, idx) => {
            let lowerOOSName = OOSName.toLowerCase()

            return (
                <Accordion styled style={{"marginTop": '10px'}} key={idx}>
                    <Accordion.Title active={activeIndexState === idx} index={idx} onClick={clickFunc}>
                        <Icon name='dropdown' />
                            {OOSName}
                    </Accordion.Title>
                    
                    <Accordion.Content active={activeIndexState === idx}>
                        <form className="ui form">
                            <div className="two fields">
                                <Field
                                    name={lowerOOSName === "delivery.com" ? `delivery_dot_com_username` : `${lowerOOSName}_username`}
                                    component={renderField}
                                    type="text"
                                    placeholder="Username"
                                    label="Username"
                                    // validate={required}
                                    // classes="ten wide"
                                />

                                <Field
                                    name={lowerOOSName === "delivery.com" ? `delivery_dot_com_password` : `${lowerOOSName}_password`}
                                    component={renderField}
                                    type="password"
                                    placeholder="password"
                                    label="password"
                                    // validate={required}
                                    // classes="ten wide"
                                />
                            </div>
                            {
                                lowerOOSName === 'ubereats'
                                ?
                                <Field
                                    name={`${lowerOOSName}_pin`}
                                    component={renderField}
                                    type="password"
                                    placeholder="pin"
                                    label="pin"
                                    // validate={required}
                                    // classes="ten wide"
                                />
                                :
                                null
                            }
                        </form>
                    </Accordion.Content>
                </Accordion>
            )
        })
    }

    render() {
        // const OOSList = ['DoorDash', 'Postmates', 'Caviar', 'Seamless', 'Ubereats', 'Clorder'];
        const OOSList = ["DoorDash", "UberEats", "Postmates", "Grubhub", "9Fold", "Allset", "Caviar", "ChowNow", "Delivery.com", "Eat24", "EatStreet", "FreshBytes", "Gloria Food", "Grubhub for Work", "Kiwi Campus", "Lodel", "Ontray", "Open Dining", "Ritual", "Seamless", "Slice",];
        const { activeIndex } = this.state

        return (
            <Blueprint
                title="Services"
                progressValue="25"
                description=""
                tallHeight={true}
                section="servicesExisting"
                // nextPage="confirm"
                nextText="Finish"
                handleFormSubmit={this.props.handleFormSubmit}
                form="servicesExisting"
                valid={this.props.valid}
                endOfSection={true}
            >
                <div>
                    <div>
                        <h3>Connect your existing online ordering or third-party Services.</h3>
                        {/* <div className="ui centered grid fluid container" style={{"marginTop": '10px'}}> */}
                        <div className="ui centered grid" style={{"marginTop": '10px'}}>
                            <div className="column">
                                <div className="ui left aligned segment service-segment">
                                    <Header as="h5">
                                        {/* {
                                            <i className="big exclamation icon"></i>
                                        } */}
                                        <Header.Content>
                                            <Header.Subheader>
                                                To simplify online ordering we connect all your various services into one easy to use system. 
                                                To connect just supply us with the <strong>username</strong> and <strong>password</strong> for each service you have. <br />
                                                <br />

                                                Remember you’ll need at least 1 live service connected for your equipment to ship. If you do not have any online ordering ordering services yet, <Link to="/services/new"><span style={{textDecoration: "underline"}}>add a New Service now.</span></Link>
                                            </Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{"marginTop": '30px'}}>
                        {this.renderOOSList(OOSList, activeIndex, this.handleClick)}
                    </div>
                </div>
            </Blueprint>
        )
    }
}

export default reduxForm({
    form: 'servicesExisting', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    // validate
})(ServicesExisting)