import _ from 'lodash'

import { FETCH_DATA, DELETE_DATA, FORM_DATA } from '../actions/types'

const INITIAL_STATE = {
    someKey: null,
    formData: null,
}

export default (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_DATA:
            return {...state, someKey: action.payload}
        case DELETE_DATA:
            return _.omit(state, action.payload)
        case FORM_DATA:
            return {...state, formData: action.payload}

        default:
            return state
    }
};