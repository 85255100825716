import React, { PureComponent } from 'react'
import { Header } from 'semantic-ui-react'
// import PropTypes from 'prop-types'

import BillingSubInfo from './BillingSubInfo'

export default class BillingInfo extends PureComponent {
    // static propTypes = {

    // }
    formatPricingInfo = (pricingInfo) => {
        if (pricingInfo) {
            // pricingInfo.replace(/\s+/g, '')
            let pricingInfoArr = pricingInfo.split('+')

            return (
                pricingInfoArr.map((el, idx, arr) => {
                    el = el.trim()
                    el = el.split(' ')

                    if (idx !== 3 ) {
                        // el = el.split(' ')
                        return (
                            idx < 2
                            ?
                            <div className="ui segment" key={idx}>
                                <div>
                                    <h3>
                                        { el[0] }
                                    </h3>
                                </div>
                                <div>
                                    <strong>{el[1]} {el[2]}</strong>
                                </div>
                            </div>
                            :
                            <div className="ui segment" key={idx}>
                                <div>
                                    <h3>
                                        { el[0] }
                                    </h3>
                                </div>
                                <div>
                                    <strong>{el[el.length - 1]}</strong>
                                </div>
                            </div>
                        )
                    }
                    if (idx === 3) {
                        return (
                            <div className="ui segment" key={idx}>
                                <div>
                                    <h3>
                                        { el[0] }
                                    </h3>
                                </div>
                                <div>
                                    <strong>{el[1]} {el[2]}</strong>
                                </div>
                            </div>
                        )
                    }
                    return null;

                    // if (idx !== 3 ) {
                    //     el = el.split(' ')
                    //     return (
                    //         idx === 0
                    //         ?
                    //         <p key={idx}>
                    //             { el[0] } / <strong>{el[2]}</strong>
                    //         </p>
                    //         :
                    //         <p key={idx}>
                    //             + { el[0] } / <strong>{el[el.length - 1]}</strong>
                    //         </p>
                    //     )
                    // }

                    // if (idx === 3) {
                    //     return <strong>{el}</strong>
                    // }
                    // return null;
                })
            )
        }
    }

    render() {
        return (
            <div className="billing-info">
                <div className="price-plan">
                    <h3>Your Plan</h3>

                    <div className="ui horizontal segments">
                        {this.formatPricingInfo(this.props.pricingInfo)}
                    </div>
                    {/* <div>
                        {this.formatPricingInfo(this.props.pricingInfo)}
                    </div> */}
                </div>
                <BillingSubInfo />

                <div className="ui left aligned basic segment">
                        <Header as="h5">
                            *Note:  
                            <Header.Content>
                                <Header.Subheader>
                                    Your billing cycle begins 10 days after your equipment is delivered OR when you process your first order. Whichever comes first.
                                    {/* <div>
                                        <p>*Note: </p>
                                        <p style={{display: "inline"}}>Your billing cycle begins 10 days after your equipment is delivered OR when you process your first order. Whichever comes first.</p>
                                    </div> */}
                                </Header.Subheader>
                            </Header.Content>
                        </Header>
                </div>

                {/* <div className="ui grid billing-info-segment">
                    <div className="fifteen wide mobile sixteen wide computer column">
                        <div className="ui left aligned basic segment">
                                <Header as="h5">
                                    <Header.Content>
                                        <Header.Subheader>
                                            <p>*Note: Your billing cycle begins 10 days after your equipment is delivered OR when you process your first order. Whichever comes first.</p>
                                        </Header.Subheader>
                                    </Header.Content>
                                </Header>
                        </div>

                        <div className="ui left aligned segment service-segment">
                                <Header as="h5">
                                    {
                                        <i className="big exclamation icon"></i>
                                    }
                                    <Header.Content>
                                        <Header.Subheader>
                                            Your billing cycle begins 10 days after your equipment is delivered OR when you process your first order. Whichever comes first.
                                        </Header.Subheader>
                                    </Header.Content>
                                </Header>
                        </div>
                    </div>
                </div> */}
            </div>
        )
    }
}
