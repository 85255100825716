export const cuisine_types = [
    {value: "Afghan", label: "Afghan"}, 
    {value: "African", label: "African"}, 
    {value: "American", label: "American"}, 
    {value: "Arabian", label: "Arabian"}, 
    {value: "Argentinean", label: "Argentinean"}, 
    {value: "Armenian", label: "Armenian"}, 
    {value: "Asian", label: "Asian"}, 
    {value: "Asian Fusion", label: "Asian Fusion"}, 
    {value: "Australian", label: "Australian"}, 
    {value: "Austrian", label: "Austrian"}, 
    {value: "Bakery", label: "Bakery"}, 
    {value: "Bangladeshi", label: "Bangladeshi"}, 
    {value: "Bar Food", label: "Bar Food"}, 
    {value: "Basque", label: "Basque"}, 
    {value: "BBQ", label: "BBQ"}, 
    {value: "Belgian", label: "Belgian"}, 
    {value: "Boba", label: "Boba"}, 
    {value: "Brasseries", label: "Brasseries"}, 
    {value: "Brazilian", label: "Brazilian"}, 
    {value: "Breakfast and Brunch", label: "Breakfast and Brunch"}, 
    {value: "British", label: "British"}, 
    {value: "Buffets", label: "Buffets"}, 
    {value: "Bulgarian", label: "Bulgarian"}, 
    {value: "Burgers", label: "Burgers"}, 
    {value: "Burmese", label: "Burmese"}, 
    {value: "Cafes", label: "Cafes"}, 
    {value: "Cafeteria", label: "Cafeteria"}, 
    {value: "Cajun", label: "Cajun"}, 
    {value: "Calabrian", label: "Calabrian"}, 
    {value: "Cambodian", label: "Cambodian"}, 
    {value: "Cantonese", label: "Cantonese"}, 
    {value: "Caribbean", label: "Caribbean"}, 
    {value: "Catalan", label: "Catalan"}, 
    {value: "Cheesesteaks", label: "Cheesesteaks"}, 
    {value: "Chicken Shop", label: "Chicken Shop"}, 
    {value: "Chicken Wings", label: "Chicken Wings"}, 
    {value: "Chinese", label: "Chinese"}, 
    {value: "Coffee and Tea", label: "Coffee and Tea"}, 
    {value: "Colombian", label: "Colombian"}, 
    {value: "Comfort Food", label: "Comfort Food"}, 
    {value: "Creperies", label: "Creperies"}, 
    {value: "Cuban", label: "Cuban"}, 
    {value: "Czech", label: "Czech"}, 
    {value: "Deli", label: "Deli"}, 
    {value: "Desserts", label: "Desserts"}, 
    {value: "Dim Sum", label: "Dim Sum"}, 
    {value: "Diners", label: "Diners"}, 
    {value: "Dinner Theater", label: "Dinner Theater"}, 
    {value: "Dominican", label: "Dominican"}, 
    {value: "Eastern European", label: "Eastern European"}, 
    {value: "Egyptian", label: "Egyptian"}, 
    {value: "Ethiopian", label: "Ethiopian"}, 
    {value: "Falafel", label: "Falafel"}, 
    {value: "Fast Food", label: "Fast Food"}, 
    {value: "Filipino", label: "Filipino"}, 
    {value: "Fish & Chips", label: "Fish & Chips"}, 
    {value: "Fondue", label: "Fondue"}, 
    {value: "Food Court", label: "Food Court"}, 
    {value: "Food Stands", label: "Food Stands"}, 
    {value: "Food Truck", label: "Food Truck"}, 
    {value: "French", label: "French"}, 
    {value: "Game Meat", label: "Game Meat"}, 
    {value: "Gastropubs", label: "Gastropubs"}, 
    {value: "Georgian", label: "Georgian"}, 
    {value: "German", label: "German"}, 
    {value: "Gluten Free", label: "Gluten Free"}, 
    {value: "Greek", label: "Greek"}, 
    {value: "Guamanian", label: "Guamanian"}, 
    {value: "Hainan", label: "Hainan"}, 
    {value: "Haitian", label: "Haitian"}, 
    {value: "Halal", label: "Halal"}, 
    {value: "Hawaiian", label: "Hawaiian"}, 
    {value: "Healthy", label: "Healthy"}, 
    {value: "Himalayan/Nepalese", label: "Himalayan/Nepalese"}, 
    {value: "Honduran", label: "Honduran"}, 
    {value: "Hong Kong Style Cafe", label: "Hong Kong Style Cafe"},
    {value: "Hot Dogs", label: "Hot Dogs"}, 
    {value: "Hot Pot", label: "Hot Pot"}, 
    {value: "Hungarian", label: "Hungarian"}, 
    {value: "Iberian", label: "Iberian"}, 
    {value: "Ice Cream and Frozen Yogurt", label: "Ice Cream and Frozen Yogurt"}, 
    {value: "Indian", label: "Indian"}, 
    {value: "Indonesian", label: "Indonesian"}, 
    {value: "Irish", label: "Irish"}, 
    {value: "Italian", label: "Italian"}, 
    {value: "Izakaya", label: "Izakaya"}, 
    {value: "Japanese", label: "Japanese"}, 
    {value: "Japanese Curry", label: "Japanese Curry"}, 
    {value: "Juice and Smoothies", label: "Juice and Smoothies"}, 
    {value: "Kebab", label: "Kebab"}, 
    {value: "Korean", label: "Korean"}, 
    {value: "Kosher", label: "Kosher"}, 
    {value: "Laotian", label: "Laotian"}, 
    {value: "Latin American", label: "Latin American"}, 
    {value: "Latin Fusion", label: "Latin Fusion"}, 
    {value: "Lebanese", label: "Lebanese"}, 
    {value: "Live/Raw Food", label: "Live/Raw Food"}, 
    {value: "Malaysian", label: "Malaysian"}, 
    {value: "Mauritius", label: "Mauritius"}, 
    {value: "Mediterranean", label: "Mediterranean"}, 
    {value: "Mexican", label: "Mexican"}, 
    {value: "Middle Eastern", label: "Middle Eastern"}, 
    {value: "Modern European", label: "Modern European"}, 
    {value: "Mongolian", label: "Mongolian"}, 
    {value: "Moroccan", label: "Moroccan"}, 
    {value: "New Mexican Cuisine", label: "New Mexican Cuisine"}, 
    {value: "Nicaraguan", label: "Nicaraguan"}, 
    {value: "Noodles", label: "Noodles"}, 
    {value: "Pakistani", label: "Pakistani"}, 
    {value: "Pan Asian", label: "Pan Asian"}, 
    {value: "Persian/Iranian", label: "Persian/Iranian"}, 
    {value: "Peruvian", label: "Peruvian"}, 
    {value: "Pizza", label: "Pizza"}, 
    {value: "Polish", label: "Polish"}, 
    {value: "Polynesian", label: "Polynesian"}, 
    {value: "Pop-Up Restaurants", label: "Pop-Up Restaurants"}, 
    {value: "Portuguese", label: "Portuguese"}, 
    {value: "Poutineries", label: "Poutineries"},
    {value: "Pretzels", label: "Pretzels"}, 
    {value: "Pub", label: "Pub"}, 
    {value: "Puerto Rican", label: "Puerto Rican"}, 
    {value: "Ramen", label: "Ramen"}, 
    {value: "Reunion", label: "Reunion"}, 
    {value: "Russian", label: "Russian"}, 
    {value: "Salads", label: "Salads"}, 
    {value: "Salvadoran", label: "Salvadoran"}, 
    {value: "Sandwiches", label: "Sandwiches"}, 
    {value: "Sardinian", label: "Sardinian"}, 
    {value: "Scandinavian", label: "Scandinavian"}, 
    {value: "Scottish", label: "Scottish"}, 
    {value: "Seafood", label: "Seafood"}, 
    {value: "Senegalese", label: "Senegalese"}, 
    {value: "Shanghainese", label: "Shanghainese"}, 
    {value: "Sicilian", label: "Sicilian"}, 
    {value: "Singaporean", label: "Singaporean"}, 
    {value: "Slovakian", label: "Slovakian"},
    {value: "Snack", label: "Snack"},
    {value: "Soul Food", label: "Soul Food"}, 
    {value: "Soup", label: "Soup"}, 
    {value: "South African", label: "South African"}, 
    {value: "Southern", label: "Southern"}, 
    {value: "Spanish", label: "Spanish"}, 
    {value: "Sri Lankan", label: "Sri Lankan"}, 
    {value: "Steakhouses", label: "Steakhouses"}, 
    {value: "Supper Clubs", label: "Supper Clubs"}, 
    {value: "Sushi", label: "Sushi"}, 
    {value: "Sushi Bars", label: "Sushi Bars"}, 
    {value: "Syrian", label: "Syrian"}, 
    {value: "Szechuan", label: "Szechuan"}, 
    {value: "Tacos", label: "Tacos"}, 
    {value: "Taiwanese", label: "Taiwanese"}, 
    {value: "Tapas/Small Plates", label: "Tapas/Small Plates"}, 
    {value: "Tapas Bars", label: "Tapas Bars"}, 
    {value: "Teppanyaki", label: "Teppanyaki"}, 
    {value: "Tex-Mex", label: "Tex-Mex"}, 
    {value: "Thai", label: "Thai"}, 
    {value: "Themed Cafes", label: "Themed Cafes"}, 
    {value: "Trinidadian", label: "Trinidadian"}, 
    {value: "Turkish", label: "Turkish"}, 
    {value: "Tuscan", label: "Tuscan"}, 
    {value: "Ukrainian", label: "Ukrainian"}, 
    {value: "Uzbek", label: "Uzbek"}, 
    {value: "Vegan", label: "Vegan"}, 
    {value: "Vegetarian", label: "Vegetarian"}, 
    {value: "Venezuelan", label: "Venezuelan"}, 
    {value: "Vietnamese", label: "Vietnamese"}, 
    {value: "Waffles", label: "Waffles"}, 
    {value: "Wine & Cheese", label: "Wine & Cheese"}, 
    {value: "Wings", label: "Wings"}, 
    {value: "Wraps", label: "Wraps"}, 
    {value: "Other", label: "Other"}, 
]

export const states = [{value: "AL", label: "AL-Alabama"}, {value: "AK", label: "AK-Alaska"}, {value: "AZ", label: "AZ-Arizona"}, {value: "AR", label: "AR-Arkansas"}, {value: "CA", label: "CA-California"}, {value: "CO", label: "CO-Colorado"}, {value: "CT", label: "CT-Connecticut"}, {value: "DC", label: "DC-District of Columbia"}, {value: "DE", label: "DE-Delaware"}, {value: "FL", label: "FL-Florida"}, {value: "GA", label: "GA-Georgia"}, {value: "HI", label: "HI-Hawaii"}, {value: "ID", label: "ID-Idaho"}, {value: "IL", label: "IL-Illinois"}, {value: "IN", label: "IN-Indiana"}, {value: "IA", label: "IA-Iowa"}, {value: "KS", label: "KS-Kansas"}, {value: "KY", label: "KY-Kentucky"}, {value: "LA", label: "LA-Louisiana"}, {value: "ME", label: "ME-Maine"}, {value: "MD", label: "MD-Maryland"}, {value: "MA", label: "MA-Massachusetts"}, {value: "MI", label: "MI-Michigan"}, {value: "MN", label: "MN-Minnesota"}, {value: "MS", label: "MS-Mississippi"}, {value: "MO", label: "MO-Missouri"}, {value: "MT", label: "MT-Montana"}, {value: "NE", label: "NE-Nebraska"}, {value: "NV", label: "NV-Nevada"}, {value: "NH", label: "NH-New Hampshire"}, {value: "NJ", label: "NJ-New Jersey"}, {value: "NM", label: "NM-New Mexico"}, {value: "NY", label: "NY-New York"}, {value: "NC", label: "NC-North Carolina"}, {value: "ND", label: "ND-North Dakota"}, {value: "OH", label: "OH-Ohio"}, {value: "OK", label: "OK-Oklahoma"}, {value: "OR", label: "OR-Oregon"}, {value: "PA", label: "PA-Pennsylvania"}, {value: "RI", label: "RI-Rhode Island"}, {value: "SC", label: "SC-South Carolina"}, {value: "SD", label: "SD-South Dakota"}, {value: "TN", label: "TN-Tennessee"}, {value: "TX", label: "TX-Texas"}, {value: "UT", label: "UT-Utah"}, {value: "VT", label: "VT-Vermont"}, {value: "VA", label: "VA-Virginia"}, {value: "WA", label: "WA-Washington"}, {value: "WV", label: "WV-West Virginia"}, {value: "WI", label: "WI-Wisconsin"}, {value: "WY", label: "WY-Wyoming"}]
