import React, { PureComponent } from 'react'
// import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

export default class Footer extends PureComponent {
    // static propTypes = {

    // }

    render() {
        return (
            <div className="ui inverted vertical footer segment footer-pad">
                <div className="ui grid fluid container">
                    {/* <div className="ui stackable centered inverted divided grid">
                        <div className="three wide center aligned column">
                            <h4 className="ui inverted header">Group 1</h4>
                            <div className="ui inverted link list">
                                <a href="#" className="item">Link One</a>
                                <a href="#" className="item">Link Two</a>
                            </div>
                        </div>
                        <div className="three wide center aligned column">
                            <h4 className="ui inverted header">Group 2</h4>
                            <div className="ui inverted link list">
                                <a href="#" className="item">Link One</a>
                                <a href="#" className="item">Link Two</a>
                            </div>
                        </div>
                        <div className="three wide center aligned column">
                            <h4 className="ui inverted header">Group 3</h4>
                            <div className="ui inverted link list">
                                <a href="#" className="item">Link One</a>
                                <a href="#" className="item">Link Two</a>
                            </div>
                        </div>
                    </div>
                    <div className="ui inverted section divider"></div> */}
                    <div className="left floated left aligned twelve wide column footer-left-stuff">
                        <div className="ui horizontal inverted small divided link list">
                            <p className="item">&copy; 2019 OrderMark</p>
                            <a href="https://www.ordermark.com/privacy/" target="_blank" rel="noopener noreferrer" className="item">
                                Privacy
                            </a>
                            <a href="https://www.ordermark.com/terms/" target="_blank" rel="noopener noreferrer" className="item">
                                Terms
                            </a>
                            {/* <Link to={`/`} className="item">
                                Privacy
                            </Link> */}
                            {/* <Link to={`/`} className="item">
                                Terms
                            </Link> */}
                        </div>
                    </div>
                    <div className="right floated center aligned two wide mobile one wide computer column footer-right-stuff">
                        <div className="ui horizontal inverted small divided link list">
                            <Link to={`/help`} className="item">
                                Help
                            </Link>
                        </div>
                    </div>
                    {/* <div className="ui grid">
                    </div> */}
                </div>
            </div>
        )
    }
}
