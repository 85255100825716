import React, { PureComponent } from 'react'
import { Segment, Icon, Header } from 'semantic-ui-react'
// import PropTypes from 'prop-types'

export default class BillingSubInfo extends PureComponent {
    // static propTypes = {

    // }

    renderList = (arr) => {
        return arr.map((el, idx) => {
            return (
                <Segment basic key={idx}>
                    <Header as="h4">
                        <Icon name='check' size="big"/>
                        <Header.Content>
                            {el}
                        </Header.Content>
                    </Header>
                </Segment>
            )
        })
    }

    render() {
        let copyArr = ["$0 to Start Up", "Risk-Free", "No Long-Term Contracts", "No Commitments", "No Surprises - Just Better Online Ordering"]

        return (
            <div className="ui basic segments">
                {this.renderList(copyArr)}
            </div>
        )
    }
}
