import _ from 'lodash'

import { FETCH_USER, FETCH_USERS, DELETE_USER } from '../actions/types'

const INITIAL_STATE = {
    someKey: null
}

export default (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_USERS:
            return { ...state, ..._.mapKeys(action.payload, 'id') }

        case FETCH_USER:
            return { ...state, [action.payload.id]: action.payload }
        
        case DELETE_USER:
            return _.omit(state, action.payload)

        default:
            return state
    }
};