import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
// import PropTypes from 'prop-types'

import { handleFormSubmit, handleHeaderTextChange,handleSectionProgress, getUserData } from '../../actions'
import RestaurantInfo from './RestaurantInfo'
import RestaurantLocation from './RestaurantLocation'
import RestaurantContactWithPassword from './RestaurantContactWithPassword'
import InstallationContact from './InstallationContact'
// import InstallationPassword from './InstallationPassword'

class index extends Component {
    // static propTypes = {
    //     prop: PropTypes
    // }

    // componentDidMount() {
    //     console.log('route appid',this.props.appId)
    //     this.props.getUserData(this.props.appId, 'restoInfo')
    // }

    render() {
        return (
            <div className="ui container">
                <Switch>
                    <Route 
                        path="/account/rest-info"
                        render={ (props) => (
                            <RestaurantInfo 
                                {...props} 
                                handleFormSubmit={this.props.handleFormSubmit} 
                                handleHeaderTextChange={this.props.handleHeaderTextChange} 
                                handleSectionProgress={this.props.handleSectionProgress}
                            />
                        )}                         
                    />

                    {/* <Route 
                        path="/account/location"
                        render={ (props) => (
                            <RestaurantLocation 
                                {...props} 
                                handleFormSubmit={this.props.handleFormSubmit} 
                                handleHeaderTextChange={this.props.handleHeaderTextChange}
                                handleSectionProgress={this.props.handleSectionProgress}
                            />
                        )}                 
                    /> */}

                    {/* <Route 
                        exact 
                        path="/account/install-contact"
                        render={ (props) => (
                            <InstallationContact 
                                {...props} 
                                handleFormSubmit={this.props.handleFormSubmit} 
                                handleHeaderTextChange={this.props.handleHeaderTextChange}
                                handleSectionProgress={this.props.handleSectionProgress}                                
                            /> 
                        )}
                    /> */}

                    <Route
                        exact
                        path={"/account" || "/account/?app_id=:app_id"}
                        render={ (props) => (
                            <RestaurantContactWithPassword 
                                {...props} 
                                handleFormSubmit={this.props.handleFormSubmit} 
                                handleHeaderTextChange={this.props.handleHeaderTextChange}
                                handleSectionProgress={this.props.handleSectionProgress}
                                name={this.props.name}
                            />
                        )} 
                    />
                </Switch>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    // return { user: state.users.find(user => user.id === ownProps.userId) }

    return {
        page: state.pages.page,
        // appId: state.users.appId,
        appId: ownProps.appId,
    }
}

export default connect(
    mapStateToProps, 
    {
        handleFormSubmit,
        handleHeaderTextChange,
        handleSectionProgress,
        getUserData,
    }
)(index)