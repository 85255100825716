import React, { PureComponent } from 'react'
import { Progress } from 'semantic-ui-react'
// import PropTypes from 'prop-types'

export default class ProgressBar extends PureComponent {
    // static propTypes = {

    // }

    render() {
        return (
            <Progress percent={this.props.value} size='small' color="teal" style={{background: "white", marginTop: "4px"}}/>
        )
    }
}
