import React, { PureComponent } from 'react'
// import PropTypes from 'prop-types'

import Iframe from './Iframe'

export default class index extends PureComponent {
    // static propTypes = {

    // }

    render() {
        return ( 
            <Iframe 
                source={this.props.source} 
                toggleNext={this.props.toggleNext} 
                appId={this.props.appId}
                id={this.props.id}
            /> 
        )
    } 
}
