import React, { PureComponent } from 'react'
import { Field, reduxForm } from 'redux-form'
import {injectStripe} from 'react-stripe-elements';
// import {injectStripe, CardNumberElement, CardExpiryElement, CardCVCElement} from 'react-stripe-elements';
// import PropTypes from 'prop-types'

// import Blueprint from '../Partials/Blueprint'
import { renderField, renderStripeField } from '../Partials/forms'
import { required } from '../Partials/forms/validate'

class CreditCardPaymentOption extends PureComponent {
    // static propTypes = {

    // }
    constructor(props) {
        super(props)
        
        this.state = {
            ccTokenExists: false,
            cc: null,
            ccNum: null,
            ccExp: null,
            ccCvc: null,
            ccZip: null,
        }
    }

    // componentDidMount(props, nextProps) {
    //     console.log(nextProps)
    //     this.toggleCCVerification()
    // }

    checkStripeFormCompletion = (e, form) => {
        console.log(e)
        if (e.complete) {
            this.setState((prevState) => {
                return {
                    [form]: true
                }
            })
            console.log(`form: ${form}, ${this.state[form]}`)
            // console.log('form: ', this.state.form)
        }
    }
    
    handleStripeSubmit = async (e) => {
        e.preventDefault();
        try {
            let token = await this.props.stripe.createToken();
            // console.log(`stripe token`)
            // console.log(token)
            await this.props.storeCCCreds(token)
            await this.toggleCCVerification()
            // actual token to send to backend is token.id
            // "tok_1EIR4lHf1cZziJcAe8iW2rxr"

            // card.id
            // "card_1EIR5iHf1cZziJcATy8ynNRG"
        } catch(err) {
            console.log(err)
        } 
    }



    toggleCCVerification = () => {
        this.setState((prevState) => {
            return {
                ccTokenExists: !prevState.ccTokenExists,
                cc: this.props.token
            }
        })
    } 
    
    render() {
        // console.log(`token exists ${JSON.stringify(this.props.ccToken)}`)
        // console.log(this.state.ccTokenExists)
        // console.log('heres the cc')
        // console.log(this.state.cc)
        console.log(this.props.ccToken ? this.props.ccToken.token : 'no card yet')
        console.log("props", this.props)
        console.log("valid?", this.props.valid)
        return (
            <div>
                { 
                    this.state.ccTokenExists 
                        ?
                        <div className="ui card">
                            <div className="content">
                                <div className="header">
                                    Card on File
                                </div>
                                {
                                    this.props.ccToken.token
                                        ? 
                                        <div>
                                            <h4>{this.props.ccToken.token.card.brand} ending in {this.props.ccToken.token.card.last4}</h4>
                                        </div>
                                        : 
                                        <div>
                                            no info
                                        </div>
                                }
                                <div style={{"marginTop": "10px"}}>
                                    <button className="circular ui button ACH-button" onClick={this.toggleCCVerification}>Edit</button>
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            <form className="ui form">
                                <h4>* = required</h4>
                                <Field
                                    name="billingCCNumber"
                                    component={renderStripeField}
                                    validate={required}
                                    label="Credit Card Number*"
                                    type="CCNum"
                                    classes="ten wide"
                                    checkStripeFormCompletion={this.checkStripeFormCompletion}
                                />
                                <div className="four fields">
                                    <Field
                                        name="billingCCExpiration"
                                        component={renderStripeField}
                                        // validate={required}
                                        label="Exp Date(MM/YY)*"
                                        type="CCExp"
                                        checkStripeFormCompletion={this.checkStripeFormCompletion}

                                    />
                                    <Field
                                        name="billingCCCVC"
                                        component={renderStripeField}
                                        // validate={required}
                                        label="CVC*"
                                        type="CCCVC"
                                        checkStripeFormCompletion={this.checkStripeFormCompletion}
                                    />
                                </div>
                                <Field
                                    name="billingCCzip"
                                    component={renderStripeField}
                                    // validate={required}
                                    label="Zip Code*"
                                    type="CCZip"
                                    classes="six wide"
                                    checkStripeFormCompletion={this.checkStripeFormCompletion}
                                />

                                {/* <Field
                                    name="billingZipCode"
                                    component={renderField}
                                    validate={required}
                                    placeholder="Zip Code"
                                    type="text"
                                    classes="six wide"
                                /> */}
                            </form>
                            {
                                (this.state.ccNum === true && this.state.ccExp === true && this.state.ccCvc === true && this.state.ccZip === true)
                                // this.props.valid
                                ?
                                <div className="ui center aligned container">
                                    <button className="circular ui button ACH-button" onClick={this.handleStripeSubmit}>
                                        Submit
                                    </button>
                                </div>
                                :
                                <div className="ui center aligned container">
                                    <button className="circular ui disabled button ACH-button" onClick={this.handleStripeSubmit}>
                                        Submit
                                    </button>
                                </div>
                            }
                            
                            {/* <div className="ui center aligned container">
                                <button className="circular ui button ACH-button" onClick={this.handleStripeSubmit}>
                                    Submit
                                </button>
                            </div> */}
                        </div>
                }
            </div>      
        )
    }
}

export default injectStripe(reduxForm({
    form: 'billingCCInfo', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    // validate
})(CreditCardPaymentOption))
// export default injectStripe(CreditCardPaymentOption)