import React, { PureComponent } from 'react'
import Footer from './Footer'
// import PropTypes from 'prop-types'

export default class Index extends PureComponent {
    // static propTypes = {

    // }

    render() {
        return (
            <div className="footer-space">
                <Footer />
            </div>
        )
    }
}
