import { HEADER_TEXT, SECTION_PROGRESS } from '../actions/types'

const INITIAL_STATE = {
    bigCopy: "",
    smallCopy: "",
    progressValue: "0",
    includeProgressBar: false,
}

export default (state=INITIAL_STATE, action) => {
    switch (action.type) {        
        case HEADER_TEXT:
            return {...state, smallCopy: action.payload.smallCopy, bigCopy: action.payload.bigCopy, includeProgressBar: action.payload.includeProgressBar}
        case SECTION_PROGRESS:
            return {...state, progressValue: action.payload}
        
        default:
            return state
    }
};