import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
// import { Button, ButtonGroup } from 'semantic-ui-react'
// import PropTypes from 'prop-types'

import history from '../../../history'
export default class BackNextButtonGroup extends PureComponent {
    // static propTypes = {

    // }
    validateNext = (e) => {
        e.preventDefault();
        // console.log('forms')
        // console.log(this.props.formVals);
        let formVals = this.props.formVals;
        let form = this.props.form;
        let section = this.props.section;
        let appId = this.props.appId

        if (this.props.endOfSection === true) {
            this.props.handleFormSubmit(formVals, form);
            this.props.handleEndOfSection(true, section, appId);
        } else {
            this.props.handleFormSubmit(formVals, form);
            history.push(`/${this.props.section}/${this.props.nextPage}`)
        }
    }
    // submitForm = () => {
    //     const section = this.props.section;
    //     console.log("submit form", section)
    //     // this.props.handleFormSubmit("test", section);
    //     console.log(this.props.currSect)
    // }
    
    renderClassNames = () => {
        let valid = this.props.valid;
        if (valid !== true) {
            return "circular ui right floated disabled button next-button"
        }
        return "circular ui right floated button next-button"
    }
    renderBackButton = () => {
        let prevPage = this.props.prevPage
        if ((prevPage && prevPage !== 'home') || this.props.nextPage === "location") {
            return (
                <Link to={`/${this.props.section}/${this.props.prevPage}`}>
                    <button className="circular ui left floated button back-button">
                        &lt; Back
                    </button>
                </Link>
            )
        } else if (prevPage && prevPage === 'home') {
            return (
                <Link to={`/?app_id=${this.props.appId}`}>
                    <button className="circular ui left floated button back-button">
                        &lt; Back
                    </button>
                </Link>
            )
        } else {
            return null
        }
    }

    render() {
        return (
            <div className="buttonPad">
                {
                    // this.props.nextPage 
                    //     ?
                    //         // <button className=`circular ui right floated ${buttonState} next-button` onClick={this.validateNext}>
                    //         <button className={this.renderClassNames()} onClick={this.validateNext}>
                    //             <span>{this.props.nextText ? this.props.nextText: 'Next'}</span>
                    //         </button>
                    //     :
                    //         // <button className={`circular ui right floated ${buttonState} next-button`}  onClick={this.submitForm}>
                    //         <button className={this.renderClassNames()} onClick={this.submitForm}>
                    //             {this.props.nextText ? this.props.nextText: 'Next'}
                    //         </button>
                    
                    <button className={this.renderClassNames()} onClick={this.validateNext}>
                        <span>{this.props.nextText ? this.props.nextText: 'Next'}</span>
                    </button>
                }

                {/* { 
                    this.renderBackButton()
                } */}
            </div>
        )
    }
}


