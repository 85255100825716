import React, { PureComponent } from 'react'
import {connect} from 'react-redux'
import { Field, reduxForm, change, getFormValues, formValueSelector } from 'redux-form'
// import PropTypes from 'prop-types'

// import Blueprint from '../Partials/Blueprint'
import { states } from '../../dummyData/constants'
import { renderField, renderSelector, renderCheckbox } from '../Partials/forms'
import { required } from '../Partials/forms/validate'
import normalizeZip from '../Partials/forms/normalizeZip';


class ShippingDetails extends PureComponent {
    // static propTypes = {

    // }
    
    render() {
        return (
            <div className="divide-top-no-divider">
            {/* <div className="divide-top"> */}
                {/* <h3>Please ship my equipment to…</h3> */}

                <div>
                    <h3>Where should we ship your equipment?</h3>
                    <form className="ui form">
                        <Field
                            name="location_shipment_option"
                            component={renderCheckbox}
                            label="Same info as restaurant location"
                        />
                        
                        {
                            !this.props.shipmentCheckbox
                            ?
                            <div>
                                <Field
                                    name="shipping_address1"
                                    component={renderField}
                                    validate={required}
                                    placeholder="Address"
                                    label="Address *"
                                    type="text"
                                />
                                <Field
                                    name="shipping_address2"
                                    component={renderField}
                                    // validate={required}
                                    placeholder="Address"
                                    type="text"
                                />
        
                                <div className="two fields">
                                    <Field
                                        name="shipping_city"
                                        component={renderField}
                                        validate={required}
                                        placeholder="City"
                                        label="City *"
                                        type="text"
                                        classes="fourteen wide"
                                    />
        
                                    <Field
                                        name="shipping_state"
                                        component={renderSelector}
                                        validate={required}
                                        arr={states}
                                        label="State *"
                                        selection="State"
                                        classes="two-field-selector"
                                    />
                                </div>
        
                                <Field
                                    name="shipping_zip"
                                    component={renderField}
                                    validate={required}
                                    normalize={normalizeZip}
                                    placeholder="Zip Code"
                                    label="Zip Code *"
                                    type="text"
                                    classes="six wide"
                                />
                            </div>
                            :
                            null
                        }
                    </form>
                </div>
            </div>
            // <Blueprint 
            //     // description="Finalize your equipment options and confirm installation details."
            //     // progressValue="16.6"
            //     // section="equipment"
            //     // prevPage="options"
            // >
            // </Blueprint>
        )
    }
}

reduxForm({
    // form: 'restoLocation', // <------ same form name
    form: 'restoInfo', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    // validate
})(ShippingDetails)

const selector = formValueSelector('restoInfo')
const mapStateToProps = (state, ownProps) => {
    const shipmentCheckbox = selector(state, 'location_shipment_option')
    let locationVals = getFormValues('restoInfo')(state)
    
    return {
        locationVals,
        shipmentCheckbox,
    }
}

export default connect(
    mapStateToProps,
    {
        change,
    }
)(ShippingDetails)