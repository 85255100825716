import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector, getFormValues, change } from 'redux-form'
// import { Checkbox } from 'semantic-ui-react'
// import PropTypes from 'prop-types'

import Blueprint from '../Partials/Blueprint'
import InstallationContactv2 from './InstallationContactv2'
import { renderField, renderPolicyCheckbox, renderRadio } from '../Partials/forms'
import { required, letters, isEmail } from '../Partials/forms/validate'
import normalizePhone from '../Partials/forms/normalizePhone'

class RestaurantContactWithPassword extends PureComponent {
    // static propTypes = {

    // }

    logVals = (e) => {
        e.preventDefault();
        console.log(this.props.formVals)
    }
    componentDidMount() {
        this.props.handleHeaderTextChange(`Hi! Welcome to Ordermark!`,`Start simplifying third-party online ordering.`,false);
        this.props.change('contact_preferred_method', 'Email')
    }

    render() {
        return (
            <Blueprint
                // title="Account Creation"
                // description="Get started with some basic contact information about you and your restaurant."
                tallHeight={true}
                progressValue="25"
                section="account"
                nextText="Next"
                nextPage="rest-info"
                // form='restoContactInfo'
                form='restoInfo'
                valid={this.props.valid}
            >
                <div>
                    {/* <div>
                    </div> */}

                    <h3>Let's get started with some basic contact information.</h3>
                    <h4>* = required</h4>
                    
                    <form className="ui form">
                        <div className="two fields">
                            <Field
                                name="contact_first_name"
                                component={renderField}
                                validate={[required, letters]}
                                placeholder="First Name"
                                label="First Name *"
                                type="text"
                                classes=""
                            />
                            <Field
                                name="contact_last_name"
                                component={renderField}
                                validate={[required, letters]}
                                placeholder="Last Name"
                                label="Last Name *"
                                type="text"
                                classes=""
                            />
                        </div>
                        <Field
                            name="contact_email"
                            component={renderField}
                            validate={[required, isEmail]}
                            placeholder="Email Address"
                            label="Email Address *"
                            type="text"
                            classes=""
                        />
                        <Field
                            name="contact_phone"
                            component={renderField}
                            validate={[required]}
                            normalize={normalizePhone}
                            placeholder="Phone Number"
                            label="Phone Number *"
                            type="text"
                            classes="ten wide"
                        />
                        <div className="communication-spacing" style={{display: "none"}}>
                            <p>What is the easiest way we can communicate with you?</p>
                            <div className="ui grid">
                                <div className="eight wide column">
                                    <Field
                                        name="contact_preferred_method"
                                        component={renderRadio}
                                        validate={required}
                                        label="Email"
                                        radioValue="Email"
                                    />
                                </div>
                                <div className="eight wide column">
                                    <Field
                                        name="contact_preferred_method"
                                        component={renderRadio}
                                        validate={required}
                                        label="Phone"
                                        radioValue="Phone"
                                    />
                                </div>
                            </div>
                        </div>
                        
                        {/* <div className="ui grid ">
                            <Field
                                name="account_terms_policy"
                                component={renderPolicyCheckbox}
                                validate={required}
                                classes="one wide column"
                            />
                            <div className="fourteen wide column tos-check-copy">
                                <div className="terms-policy">
                                    I agree to the&nbsp;
                                    <a href="https://www.ordermark.com/terms/" target="_blank" rel="noopener noreferrer">
                                        Terms of Service
                                    </a>
                                    &nbsp;and&nbsp;
                                    <a href="https://www.ordermark.com/privacy/" target="_blank" rel="noopener noreferrer">
                                        Privacy Policy
                                    </a>
                                    &nbsp;*
                                </div>
                            </div>
                        </div> */}

                        {/* <div className="divide-top">
                            <Field
                                name="account_username"
                                component={renderField}
                                validate={required}
                                placeholder="Account Owner Username"
                                type="text"
                                classes="ten wide"
                            />
                            <Field
                                name="account_password"
                                component={renderField}
                                validate={required}
                                placeholder="Password"
                                type="password"
                                classes="ten wide"
                            />
                            <p>* password must be at least 8 characters</p>
                            <Field
                                name="account_confirm_password"
                                component={renderField}
                                validate={required}
                                placeholder="Confirm Password"
                                type="password"
                                classes="ten wide"
                            />

                            <div className="ui grid">
                                <Field
                                    name="account_terms_policy"
                                    component={renderPolicyCheckbox}
                                    validate={required}
                                    classes="one wide column"
                                />
                                <div className="fourteen wide column tos-check-copy">
                                    <div className="terms-policy">
                                        I agree to the&nbsp;
                                        <a href="https://www.ordermark.com/terms/" target="_blank" rel="noopener noreferrer">
                                            Terms of Service
                                        </a>
                                        &nbsp;and&nbsp;
                                        <a href="https://www.ordermark.com/privacy/" target="_blank" rel="noopener noreferrer">
                                            Privacy Policy
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </form>
                    <InstallationContactv2 />
                </div>
            </Blueprint>
        )
    }
}

RestaurantContactWithPassword = reduxForm({
    // form: 'restoContactInfo', // <------ same form name
    form: 'restoInfo', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: false, // <------ unregister fields on unmount
    // validate
})(RestaurantContactWithPassword)

// const selector = formValueSelector('restoContactInfo')
// const selector = formValueSelector('restoInfo')

const mapStateToProps = (state, ownProps) => {
    // return { user: state.users.find(user => user.id === ownProps.userId) }
    // const formVals = selector(state, 'contactName', 'contactEmail', 'restoContactCommunicationOption')
    // const formVals = getFormValues('restoContactInfo')(state)

    const formVals = getFormValues('restoInfo')(state)

    return {
        formVals,
        initialValues: state.account,
        change,
    }
}

RestaurantContactWithPassword = connect(
    mapStateToProps, 
    {
    }
)(RestaurantContactWithPassword)

export default RestaurantContactWithPassword