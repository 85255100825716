import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector, change } from 'redux-form'
import {Elements} from 'react-stripe-elements';
// import PropTypes from 'prop-types'

import { storeACHCreds, storeCCCreds, toggleACH } from '../../actions'
// import { renderRadio } from '../Partials/forms'
import { renderRadio, renderField, renderSelector, renderRadioWithLabels } from '../Partials/forms'
import { required } from '../Partials/forms/validate'
import Blueprint from '../Partials/Blueprint'
import BillingInfo from './BillingInfo'
// import Plaid from './Plaid'
// import ACHPaymentOption from './ACHPaymentOption'
import CreditCardPaymentOption from './CreditCardPaymentOption'
// import normalizeCard from '../Partials/forms/normalizeCard';
// import normalizeDate from '../Partials/forms/normalizeDate';
// import normalizeZip from '../Partials/forms/normalizeZip';

class PaymentOptions extends Component {
    // static propTypes = {

    // }
    
    componentDidMount() {
        this.props.handleHeaderTextChange("Step 3 - Payment Information",null);
        this.props.change('billing_option', 'Credit Card')
    }

    toggleButtons = () => {
        if (this.props.billingOptionValue === 'ACH') {
            return false
        } else {
            return this.props.ccToken === null ? true : false
        }
    }

    togglePaymentTypes = () => {
        let billingOptionValue = this.props.billingOptionValue

        if (billingOptionValue === "Credit Card") {
            this.props.toggleACH(null)
            return (
                <Elements>
                    <CreditCardPaymentOption
                        storeCCCreds={this.props.storeCCCreds}
                        ccToken={this.props.ccToken}
                    />
                </Elements>

                // <div>
                //     <div className="ui grid cc-forms">
                //         <h4>* = required</h4>
                //         <div className="row">
                //             <div className="column cc-acct-info">
                //                 <Field 
                //                     name="cc_number"
                //                     component={renderField}
                //                     validate={required}
                //                     normalize={normalizeCard}
                //                     placeholder="Card Number"
                //                     label="Card Number *"
                //                     type="text"
                //                 />
                //             </div>
                //         </div>
                //         <div className="row">
                //             <div className="ten wide column">
                //                 <Field 
                //                     name="cc_expiry_date"
                //                     component={renderField}
                //                     validate={required}
                //                     normalize={normalizeDate}
                //                     placeholder="Exp. Date"
                //                     label="Exp. Date(MM/YY) *"
                //                     type="text"
                //                 />
                //             </div>
                //             <div className="four wide column">
                //                 <Field
                //                     name="cc_cvc"
                //                     component={renderField}
                //                     validate={required}
                //                     placeholder="CVC"
                //                     label="CVC *"
                //                     type="text"
                //                 />
                //             </div>
                //         </div>
                //         <div className="row">
                //             <div className="column">
                //                 <Field 
                //                     name="cc_zip"
                //                     component={renderField}
                //                     validate={required}
                //                     normalize={normalizeZip}
                //                     placeholder="Zip Code"
                //                     label="Zip Code *"
                //                     type="text"
                //                 />
                //             </div>
                //         </div>
                //     </div>
                // </div>
            )
            
        } else if (billingOptionValue === "ACH") {
            this.props.toggleACH(true)
            // const achAcctType = [{value: "checking", label: "checking"}, {value: "savings", label: "savings"}]
            // const achClassType = [{value: "business", label: "business"}, {value: "personal", label: "personal"}]
            return (
                // <ACHPaymentOption 
                //     storeACHCreds={this.props.storeACHCreds}
                //     ACHToken={this.props.ACHToken}
                //     ACHMetadata={this.props.ACHMetadata}
                // />
                // <Plaid 
                //     storeACHCreds={this.props.storeACHCreds}
                //     ACHToken={this.props.ACHToken}
                //     ACHMetadata={this.props.ACHMetadata}
                // />

                <div>
                    <h4>* = required</h4>
                    <Field 
                        name="ach_account_number"
                        component={renderField}
                        validate={required}
                        placeholder="Account Number"
                        label="Account Number *"
                        type="text"
                    />
                    <Field 
                        name="ach_routing_number"
                        component={renderField}
                        validate={required}
                        placeholder="Routing Number"
                        label="Routing Number *"
                        type="text"
                    />
                    <Field
                        name="ach_bank_name"
                        component={renderField}
                        validate={required}
                        placeholder="Bank Name"
                        label="Bank Name *"
                        type="text"
                    />
                    <div className="radio-group-bottom-space divide-top-no-divider">
                        <div className="ui grid">
                            <div className="ui form">
                                <div className="field">
                                    <label>Account Classification Type *</label>
                                </div>
                            </div>

                            <div className="row" style={{paddingTop: "0px"}}>
                                <div className="eight wide column">
                                    <Field
                                        name="ach_classification_type"
                                        component={renderRadio}
                                        validate={required}
                                        label="Business"
                                        radioValue="Business"
                                    />
                                </div>
                                <div className="eight wide column">
                                    <Field
                                        name="ach_classification_type"
                                        component={renderRadio}
                                        label="Personal"
                                        validate={required}
                                        radioValue="Personal"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="radio-group-bottom-space divide-top-no-divider">
                        <div className="ui grid">
                            <div className="ui form">
                                <div className="field">
                                    <label>Account Type *</label>
                                </div>
                            </div>
                            <div className="row" style={{paddingTop: "0px"}}>
                                <div className="eight wide column">
                                    <Field
                                        name="ach_account_type"
                                        component={renderRadio}
                                        validate={required}
                                        label="Checking"
                                        radioValue="Checking"
                                    />
                                </div>
                                <div className="eight wide column">
                                    <Field
                                        name="ach_account_type"
                                        component={renderRadio}
                                        label="Savings"
                                        validate={required}
                                        radioValue="Savings"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <Field
                        name="ach_classification_type"
                        component={renderSelector}
                        validate={required}
                        arr={achClassType}
                        label="Account Classification Type *"
                        selection="account class"
                        classes="eight wide mobile six wide tablet six wide computer price-plan"
                    />
                    <Field
                        name="ach_account_type"
                        component={renderSelector}
                        validate={required}
                        arr={achAcctType}
                        label="Account Type *"
                        selection="account type"
                        classes="eight wide mobile six wide tablet six wide computer price-plan"
                    /> */}
                </div>
            )
        } else {
            return null
        }
    }

    render() {
        return (
            <Blueprint
                title="Step 2 - Billing Setup"
                progressValue="41"
                section="billing"
                prevPage="home"
                nextText='Continue'
                // nextText={this.props.billingOptionValue === "Credit Card" ? "Pay" : null}
                valid={this.props.valid}
                // valid={this.props.valid && (!!this.props.ACHToken || !!this.props.ccToken)}
                // hideButtons = {(this.props.billingOptionValue === "ACH" ? false : true) || (this.props.ccToken === null ? true : false)}
                hideButtons = {this.props.ACHToken === null && this.props.ccToken === null ? true : false}
                // hideButtons = {false}
                form="billingInfo"
                endOfSection={true}
            >
                <div>
                    <h3>Review your plan and setup your preferred secure payment options.</h3>
                    <BillingInfo pricingInfo={this.props.pricingInfo}/>

                    <div>
                        <form className="ui form">
                            <div>
                                <h5>How would you like to pay</h5>
                                <div className="ui grid radio-group-bottom-space">
                                    <div className="eight wide column">
                                        <Field
                                            name="billing_option"
                                            component={renderRadio}
                                            validate={required}
                                            label="Credit Card"
                                            radioValue="Credit Card"
                                        />
                                    </div>
                                    <div className="eight wide column">
                                        <Field
                                            name="billing_option"
                                            component={renderRadio}
                                            label="ACH"
                                            validate={required}
                                            radioValue="ACH"
                                        />
                                    </div>
                                </div>
                                {this.togglePaymentTypes()}
                            </div>
                        </form>
                        
                        {/* {this.togglePaymentTypes()} */}
                    </div>
                </div>
            </Blueprint>
        )
    }
}

PaymentOptions = reduxForm({
    form: 'billingInfo', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    // validate
})(PaymentOptions)

const selector = formValueSelector('billingInfo')
const mapStateToProps = (state, ownProps) => {
    // return { user: state.users.find(user => user.id === ownProps.userId) }
    const billingOptionValue = selector(state, 'billing_option')

    return {
        billingOptionValue,
        ccToken: state.tokens.ccToken,
        pricingInfo: state.users.pricing,
    }
}

PaymentOptions = connect(
    mapStateToProps, 
    {
        storeACHCreds,
        toggleACH,
        storeCCCreds,
        change,
    }
)(PaymentOptions)

export default PaymentOptions