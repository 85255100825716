import React, {useState, useEffect} from 'react'
import { Form } from 'semantic-ui-react'
// import { Button, Checkbox, Form } from 'semantic-ui-react'
import {injectStripe, CardNumberElement, CardExpiryElement, CardCVCElement, PostalCodeElement} from 'react-stripe-elements';


const fieldClassNames = (isTouched, error, classes) => `${classes} field ${error && isTouched ? 'error' : ''}`
const renderError = (isTouched, error) => {
    // console.log(meta)
    if (isTouched && error) {
        return (
            <div>
                <div>{error}</div>
            </div>
        )
    }
}

export const renderField = ({ input, label, meta: {touched, error}, placeholder, type, classes }) => {
    return (

        <div className={fieldClassNames(touched, error, classes)}>
            <label>{label}</label>
            <input {...input} autoComplete="off" placeholder={placeholder} type={type}/>
            {/* {touched && error && } */}
            {/* {this.renderError(meta)} */}
            {/* {console.log('t', touched, 'e', error)} */}
            {renderError(touched, error)}
        </div>
    )
}

export const renderSelector = ({ input, meta: { touched, error }, classes, arr, selection, dropdownType, label }) => {
    return (
        <div className={fieldClassNames(touched, error, classes)}>
            <label>{label}</label>
            <select {...input} className="ui selection dropdown">
                <option value="">{selection}</option>
                {
                    arr.map((val, idx, arr) => {
                        // let shortVal = null;

                        // if (arr[0][2] === "-") {
                        //     shortVal = val.slice(0,2)
                        // }
                        
                        return (
                            <option value={val.value} key={val.value}>
                                {val.label}
                            </option>
                        )
                    })
                }
            </select>
            {renderError(touched, error)}
            {/* {touched && error && <span>{error}</span>} */}
        </div>
    )
}

export const renderCheckbox = (field, props) => {
    // console.log(field)
    return (
        <Form.Checkbox
            checked={!!field.input.value}
            name={field.input.name}
            label={field.label}
            onChange={(e, { checked }) => field.input.onChange(checked)}
            className={props.classes}
        />
    )
}
export const renderPolicyCheckbox = (field, props) => {
    // console.log(field)
    return (
        <Form.Checkbox
            checked={!!field.input.value}
            name={field.input.name}
            label={field.label}
            onChange={(e, { checked }) => field.input.onChange(checked)}
            className="one wide column"
            // className={props.classes}
        />
    )
}

export const renderRadio = (field) => {
    return (
        <Form.Radio
            checked={field.input.value === field.radioValue}
            label={field.label}
            name={field.input.name}
            onChange={(e, { checked }) => field.input.onChange(field.radioValue)}
        />
    );
}

export const renderTextArea = field => {
    return (
        <Form.TextArea
            {...field.input}
            label={field.label}
            placeholder={field.placeholder}
        />
    )
}

export const renderStripeField = (props) => {
// export const renderStripeField = ({ input, label, meta: {touched, error}, placeholder, type, classes }, props) => {
    const {
        input,
        label,
        classes,
        meta: {
            touched,
            error,
        },
        type,
        checkStripeFormCompletion
    } = props
    // const [formInput, checkInput] = useState(null);
    // useEffect(() => {
    //     let handleInputChange = (someInput) => {
    //         console.log('input', someInput);
    //         checkInput(someInput)
    //     }

    //     handleInputChange(input.value);
    //     return () => {

    //     }
    // })

    let stripeDiv = {
        'CCNum': <CardNumberElement className={`stripe-fields`} placeholder="Credit Card Number" onChange={(e)=>checkStripeFormCompletion(e, 'ccNum')}/>,
        'CCExp': <CardExpiryElement className={`stripe-fields`} placeholder="Exp Date(MM/YY)" onChange={(e)=>checkStripeFormCompletion(e, 'ccExp')}/>,
        'CCCVC': <CardCVCElement className={`stripe-fields`} placeholder="CVC"  onChange={(e)=>checkStripeFormCompletion(e, 'ccCvc')}/>,
        'CCZip': <PostalCodeElement className={`stripe-fields`} placeholder="Zip Code" onChange={(e)=>checkStripeFormCompletion(e, 'ccZip')}/>
    };
    console.log('touched?', props)
    return (
        <div className={fieldClassNames(touched, error, classes)}>
        {/* <div className={fieldClassNames(touched, error, classes)}> */}
            <label>{label}</label>
            {stripeDiv[type]}
            {/* {renderError(touched, error)} */}
        </div>
        // <div>
        //     <div className={fieldClassNames(touched, error, classes)} style={{}}>
        //         <label>{label}</label>
        //         <input {...input} autoComplete="off" placeholder={placeholder} type={type}/>
        //         {/* {touched && error && } */}
        //         {/* {this.renderError(meta)} */}
        //         {/* {console.log('t', touched, 'e', error)} */}
        //         {renderError(touched, error)}
        //     </div>
        //     <div>
        //         {formInput}
        //     </div>
        // </div>
    )
}


// export const renderCheckbox = ({ input, meta: { touched, error }, classes, selection, checkboxLabel, boxName }) => {
//     console.log(boxName)
//     return (
//         <div className="ui checkbox">
//             <input type="checkbox" name={boxName}/>
//             <label htmlFor={boxName}>{checkboxLabel}</label>
//         </div>
//     )
// }

export const onSubmit = (formValues) => {
    this.props.onSubmit(formValues)
}