import { AUTH_CHECK, STORE_ACH, STORE_CC } from '../actions/types'

const INITIAL_STATE = {
    appId: null,
    ACHToken: null,
    ACHMetadata: null,
    ccToken: null
}

export default (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case AUTH_CHECK:
            return { ...state, appId: action.payload }
        case STORE_ACH:
            return {...state, ACHToken: action.payload.token, ACHMetadata: action.payload.metadata}
        case STORE_CC:
            return {...state, ccToken: action.payload.token}
        default:
            return state
    }
};