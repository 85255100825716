import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Field , reduxForm, formValueSelector, getFormValues, change } from 'redux-form'
import { Link } from 'react-router-dom'
import { Header } from 'semantic-ui-react'
// import PropTypes from 'prop-types'

import Blueprint from '../Partials/Blueprint'
import { renderField, renderRadio, renderCheckbox, renderTextArea } from '../Partials/forms'
import { required, letters, isEmail } from '../Partials/forms/validate'
import normalizePhone from '../Partials/forms/normalizePhone';

class NewServices extends PureComponent {
    // static propTypes = {

    // }
    componentDidMount() {
        this.props.handleHeaderTextChange("Step 4 - Add A New Service",null);
    }

    componentDidUpdate(prevProps) {
        this.copyAccountInfo()
        // if (this.props.ownerCheckbox === true) {
        // }
        if (this.props.ownerCheckbox === false && this.props.ownerCheckbox !== prevProps.ownerCheckbox) {
            this.props.change('nss_contact_first_name', '')
            this.props.change('nss_contact_last_name', '')
            this.props.change('nss_contact_email', '')
            this.props.change('nss_contact_phone', '')
        }
    }

    copyAccountInfo = () => {
        if (this.props.ownerCheckbox === true) {
            this.props.change('nss_contact_first_name', localStorage.getItem("contact_first_name"))
            this.props.change('nss_contact_last_name', localStorage.getItem("contact_last_name"))
            this.props.change('nss_contact_email', localStorage.getItem("contact_email"))
            this.props.change('nss_contact_phone', localStorage.getItem("contact_phone"))
        }
    }

    render() {
        console.log(this.props.ownerCheckbox)
        return (
            <Blueprint
                title="Services"
                progressValue="25"
                description="Request NEW Services"
                tallHeight={true}
                section="services"
                form="servicesNew"
                // nextPage="confirm"
                nextText="Finish"
                valid={this.props.valid}
                endOfSection={true}
            >
                <div>
                    <div>
                        <h3>We will email the contact provided with available services in your area and next steps!</h3>
                        {/* <h3>Let’s explore adding new services to your Restaurant.</h3> */}
                        <div className="ui centered grid" style={{marginBottom: '20px', marginTop: '10px'}}>
                            <div className="column">
                                <div className="ui left aligned segment service-segment">
                                    <Header as="h5">
                                        {/* {
                                            <i className="big exclamation icon"></i>
                                            
                                        } */}
                                        <Header.Content>
                                            <Header.Subheader>
                                                Remember your account needs at least 1 live service connected for your equipment to ship. 
                                                If you currently have any online ordering services, those MUST be connected before working with our New Services team.
                                                <br />
                                                <br />
                                                If you have any online ordering ordering services, <Link to="/services/existing"><span style={{textDecoration: "underline"}}>click here.</span></Link>
                                            </Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </div>
                            </div>
                        </div>

                        {/* <div style={{marginBottom: "20px"}}>
                            <h4>
                                Services will be for:
                            </h4>
                            {
                                localStorage.getItem("location_address2")
                                ?
                                <p>
                                    {this.props.restFormVals.restaurant_name !== undefined ? this.props.restFormVals.restaurant_name : localStorage.get("restaurant_name")}<br />
                                    {localStorage.getItem("restaurant_name")}<br />
                                    {localStorage.getItem("location_address1")} <br />
                                    {localStorage.getItem("location_address2")} <br />
                                    {localStorage.getItem("location_city")}, {localStorage.getItem("location_state")} <br />
                                    {localStorage.getItem("location_zip")}<br />
                                </p>
                                :
                                <p>
                                    {this.props.restFormVals.restaurant_name !== undefined ? this.props.restFormVals.restaurant_name : localStorage.get("restaurant_name")}<br />
                                    {localStorage.getItem("restaurant_name")}<br />
                                    {localStorage.getItem("location_address1")} <br />
                                    {localStorage.getItem("location_city")}, {localStorage.getItem("location_state")} <br />
                                    {localStorage.getItem("location_zip")}<br />
                                </p>                                
                            }
                        </div> */}
                    </div>
                    <div>
                        <form className="ui form">
                            <Field
                                name="service_owner_box"
                                component={renderCheckbox}
                                label="Same info as the Account Owner"
                            />
                            <h4>* = required</h4>

                            <div className="price-plan">
                                <div className="two fields">
                                    <Field
                                        name="nss_contact_first_name"
                                        component={renderField}
                                        validate={[required, letters]}
                                        placeholder="First Name"
                                        label="First Name *"
                                        type="text"
                                        // classes=""
                                    />
                                    <Field
                                        name="nss_contact_last_name"
                                        component={renderField}
                                        validate={[required, letters]}
                                        placeholder="Last Name"
                                        label="Last Name *"
                                        type="text"
                                        // classes=""
                                    />
                                </div>
                                <Field
                                    name="nss_contact_email"
                                    component={renderField}
                                    validate={[required, isEmail]}
                                    placeholder="Email"
                                    label="Email *"
                                    type="text"
                                    classes=""
                                />
                                <Field
                                    name="nss_contact_phone"
                                    component={renderField}
                                    validate={[required]}
                                    normalize={normalizePhone}
                                    placeholder="Phone Number"
                                    label="Phone Number *"
                                    type="text"
                                    classes="ten wide"
                                />
                            </div> 

                            {/* {
                                !this.props.ownerCheckbox 
                                ?
                                <div className="price-plan">
                                    <div className="two fields">
                                        <Field
                                            name="nss_contact_first_name"
                                            component={renderField}
                                            validate={[required, letters]}
                                            placeholder="First Name"
                                            label="First Name"
                                            type="text"
                                            // classes=""
                                        />
                                        <Field
                                            name="nss_contact_last_name"
                                            component={renderField}
                                            validate={[required, letters]}
                                            placeholder="Last Name"
                                            label="Last Name"
                                            type="text"
                                            // classes=""
                                        />
                                    </div>
                                    <Field
                                        name="nss_contact_email"
                                        component={renderField}
                                        validate={[required, isEmail]}
                                        placeholder="Email"
                                        label="Email"
                                        type="text"
                                        classes=""
                                    />
                                    <Field
                                        name="nss_contact_phone"
                                        component={renderField}
                                        validate={[required]}
                                        normalize={normalizePhone}
                                        placeholder="Phone Number"
                                        label="Phone Number"
                                        type="text"
                                        classes="ten wide"
                                    />
                                </div> 
                                :
                                null
                            } */}

                            <div className="service-drivers">
                                <p>Do you have your own delivery drivers? *</p>
                                <div className="ui grid">
                                    <div className="eight wide column">
                                        <Field
                                            name="nss_delivery_drivers"
                                            component={renderRadio}
                                            validate={required}
                                            label="Yes"
                                            radioValue="Yes"
                                        />
                                    </div>
                                    <div className="eight wide column">
                                        <Field
                                            name="nss_delivery_drivers"
                                            component={renderRadio}
                                            validate={required}
                                            label="No"
                                            radioValue="No"
                                        />
                                    </div>
                                </div>
                            </div>

                            <Field 
                                component={renderTextArea}
                                name="nss_notes"
                                placeholder="Any notes or special requests?"
                                label="Notes"
                            />
                        </form>
                    </div>
                </div>
            </Blueprint>
        )
    }
}

NewServices = reduxForm({
    form: 'servicesNew', // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    // validate
})(NewServices)

const selector = formValueSelector('servicesNew')
const mapStateToProps = (state, ownProps) => {
    // return { user: state.users.find(user => user.id === ownProps.userId) }
    let ownerCheckbox = selector(state, 'service_owner_box')
    let restFormVals = getFormValues('restoInfo')(state)

    return {
        ownerCheckbox,
        restFormVals,
    }
}

export default connect(
    mapStateToProps, 
    {
        change,
    }
)(NewServices)